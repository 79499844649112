import Config from '../core/Config';
import DOM from '../helpers/Browser/DOM';
import { Control } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import { easeOut } from 'ol/easing';
import { addContextMenuItem } from '../common/ContextMenu';
import { SVGPaths, getIcon } from '../core/Icons';
import { isShortcutKeyOnly } from '../helpers/ShortcutKeyOnly';
import Toast from '../common/Toast';

const DEFAULT_OPTIONS = {};

class Home extends Control {
    constructor(options = {}) {
        const map = document.getElementById('ol-map');
        const duration = {duration: 1000}
        super({
            element: document.getElementById(options.element),
            target: document.getElementById(options.target)
        });
        const icon = getIcon({
            path: SVGPaths.Home,
            class: 'oltb-tool-button__icon'
        });

        const button = DOM.createElement({
            element: 'button',
            html: icon,
            class: 'oltb-tool-button',
            attributes: {
                type: 'button',
                'data-tippy-content': 'Zoom home (H)'
            },
            listeners: {
                'click': this.handleClick.bind(this)
            }
        });

        this.element.appendChild(button);
        this.options = { ...DEFAULT_OPTIONS, ...options };

        this.duration = duration;

    }

    handleClick() {
        if(typeof this.options.click === 'function') {
            this.options.click();
        }
    }
    
   /* handleResetToHome() {
        const view = this.getMap().getView();

        let extent = StateManager.getStateObject('extent')

        console.log('extent',extent)

        view.fit(extent,  this.duration );
    }

    setHomeLocation() {
        const view = this.getMap().getView();
        //  view.fit(this.defaultExtent, 1000);

        Toast.success({text: 'Home location successfully set'});
    }*/
}


export default Home;