import Home from "../Pages/Home";
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import Map from "../Pages/Map/Map";
import ContactForm from "../Pages/ContactForm";
import RequestForm from "../Pages/RequestForm";
import Navbar from "../Pages/Layouts/Navbar"
import Help from "../Pages/Help"

const index = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
      <div className="min-h-screen flex flex-col" style={{ height: "100vh" }}>
      <Navbar />
        <Routes>
        <Route path="/" element={<Map />} />
        <Route path="/contact-us" element={<ContactForm />} />
        <Route path="/request-data" element={<RequestForm />} />
        <Route path="/map" element={<Map />} />
        <Route path="/help" element={<Help />} />

        </Routes>
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default index;
