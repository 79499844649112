import React from "react";
import {PropTypes} from "prop-types";
import MapComponent from "../../Map";
import "./sidebar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faCaretLeft} from "@fortawesome/free-solid-svg-icons";

class Tab extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        anchor: PropTypes.oneOf(["top", "bottom"]),
        disabled: PropTypes.bool,
        // Provided by the Sidebar; don't mark as required (user doesn't need to include them):
        onClose: PropTypes.func,
        onClick: PropTypes.func,
        closeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        position: PropTypes.oneOf(["left", "right"]),
        active: PropTypes.bool,
    };

    render() {
        const active = this.props.active ? " active" : "";
        var closeIcon;
        if (typeof this.props.closeIcon === "string")
            closeIcon = <FontAwesomeIcon icon={this.props.closeIcon}/>;
        else if (typeof this.props.closeIcon === "object")
            closeIcon = this.props.closeIcon;
        else {
            const closecls =
                this.props.position === "right" ? faCaretRight : faCaretLeft;
            closeIcon = <FontAwesomeIcon icon={closecls}/>;
        }

        return (
            <div id={this.props.id} className={"sidebar-pane" + active}>
                <h1 className="sidebar-header bg-blue-800 ">
                    {this.props.header}
                    <div className="sidebar-close" onClick={this.props.onClose}>
                        {closeIcon}
                    </div>
                </h1>
                {this.props.children}
            </div>
        );
    }
}

// https://github.com/facebook/react/issues/2979#issuecomment-222379916
const TabType = PropTypes.shape({
    type: PropTypes.oneOf([Tab]),
});
var content = "";

class Sidebar extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        originalTitle: PropTypes.string,
        dataContainer: PropTypes.string,
        dataPlacement: PropTypes.string,
        title: PropTypes.string,
        collapsed: PropTypes.bool,
        position: PropTypes.oneOf(["left", "right"]),
        selected: PropTypes.string,
        closeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
            .isRequired,
        onClose: PropTypes.func,
        onOpen: PropTypes.func,
        updateWindows: PropTypes.func,
        children: PropTypes.oneOfType([PropTypes.arrayOf(TabType), TabType])
            .isRequired,
    };

    updateWindows(e) {
        this.props.updateWindows && this.props.updateWindows();
    }

    onClose(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClose && this.props.onClose();
        this.updateWindows(e);
    }

    onOpen(e, tabid) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onOpen && this.props.onOpen(tabid);
        this.updateWindows(e);
    }

    renderTab(tab) {
        var icon;
        if (typeof tab.props.icon === "string")
            icon = <i className={tab.props.icon}/>;
        else if (typeof tab.props.icon === "object") icon = tab.props.icon;
        const active = tab.props.id === this.props.selected ? " active" : "";
        const disabled = tab.props.disabled ? " disabled" : "";

        return (
            <li
                className={active + disabled}
                key={tab.props.id}
                data-tippy-content={tab.props.header}
            >
                <a
                    href={"#" + tab.props.id}
                    role="tab"
                    onClick={(e) => {
                        console.log('tab.props',tab.props)
                        if (tab.props.onClick) {
                            return tab.props.onClick()
                        } else {
                            return tab.props.disabled || this.onOpen(e, tab.props.id)
                        }
                    }
                    }
                >
                    {icon}
                </a>
            </li>
        );
    }

    renderPanes(children) {
        return React.Children.map(children, (p) =>
            React.cloneElement(p, {
                onClose: this.onClose.bind(this),
                closeIcon: this.props.closeIcon,
                active: p.props.id === this.props.selected,
                position: this.props.position || "left",
            })
        );
    }

    // Override render() so the <Map> element contains a div we can render to
    render() {
        const position = " sidebar-" + (this.props.position || "left");
        const collapsed = this.props.collapsed ? " collapsed" : "";

        const tabs = React.Children.toArray(this.props.children);
        const bottomtabs = tabs.filter((t) => t.props.anchor === "bottom");
        const toptabs = tabs.filter((t) => t.props.anchor !== "bottom");

        return (
            <div
                id={this.props.id}
                className={"sidebar ol-touch " + position + collapsed}
                ref={(el) => (this.rootElement = el)}
            >
                <div className="sidebar-tabs bg-blue-900">
                    <ul role="tablist">
                        {" "}
                        {/* Top-aligned */}
                        {toptabs.map((t) => this.renderTab(t))}
                    </ul>
                    <ul role="tablist">
                        {" "}
                        {/* Bottom-aligned */}
                        {bottomtabs.map((t) => this.renderTab(t))}
                    </ul>
                </div>
                <div id="sidebartb"></div>
                <div className="sidebar-content bg-gray-200">
                    {this.renderPanes(this.props.children)}
                </div>
            </div>
        );
    }
}

export {Sidebar, Tab};
