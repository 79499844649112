import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { authReducer } from "./reducers/userReducers";

const reducer = combineReducers({
  auth: authReducer,
});

const sessionCookie = JSON.parse(sessionStorage.getItem("authUser"));
const jwt = JSON.parse(sessionStorage.getItem("jwt"));
const isAuthenticated = JSON.parse(sessionStorage.getItem("authUser"))
  ? true
  : false;

const initialState = {
  auth: { user: sessionCookie, jwt: jwt, loading: false, isAuthenticated },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
