import React, {useEffect, useRef, useState} from "react";
import layersIcon from "../../assets/icons/layers.svg";
import {
    Button, Modal, Label, TextInput, FileInput, Textarea, Badge, Checkbox, Table, Tabs
} from 'flowbite-react'
import View from "ol/View";
import Overlay from "ol/Overlay";
import Map from "ol/Map";
import ol_control_SearchNominatim from "ol-ext/control/SearchNominatim";
import "ol-ext/dist/ol-ext.css";
import * as layer from "ol/layer";
import VectorLayer from "ol/layer/Vector";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import {defaults as defaultControls} from "ol/control";
import * as source from "ol/source";
import {getCenter} from "ol/extent";
import {defaults} from "ol/interaction";
import {GeoJSON, WMSCapabilities, WMSGetFeatureInfo, MVT} from "ol/format";
import {transform, transformExtent} from "ol/proj";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import LayerSwitcher from "ol-layerswitcher";
import "ol/ol.css";
import {api, kpis} from "../../config";
import * as url from "../../helpers/url_helper";
import * as client from "../../apis/APIClient";
import "./Map.css";
import "../../assets/scss/map.scss";
import "./Sidebar/SidebarControl";
import Home from "./tools/Home";
import ZoomIn from "./tools/ZoomIn";
import ZoomOut from "./tools/ZoomOut";
import FullScreen from "./tools/Fullscreen";
import ExportPNG from "./tools/ExportPNG";
import MeasureTool from "./tools/MeasureTool";
import Layers from "./tools/Layers";
import Overview from "./tools/Overview";
import GraticuleTool from "./tools/Graticule";
import MyLocation from "./tools/MyLocation";
import ScaleLineTool from "./tools/ScaleLineTool";
import Refresh from "./tools/Refresh";
import Print from "./tools/Print";
import Grid from "./tools/Grid";
import Pan from "./tools/Pan";
import Info from "./tools/Info";
import {AttributeTable, InfoWindow, Search} from "./tools/Toolbox";
import majidataLogo from "../../assets/img/majidata-logo.png"
import wtfLogo from "../../assets/img/wtf-logo-color.png"
import InfoWindowManager from "./core/Managers/InfoWindowManager";
import LayerManager from "./core/Managers/LayerManager";
import StateManager from "./core/Managers/StateManager";
import TooltipManager from "./core/Managers/TooltipManager";
import Dialog from "./common/Dialog";
import ContextMenu from "./common/ContextMenu";
import {Sidebar, Tab} from "./Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faLayerGroup,
    faSearch,
    faUpload,
    faXmark,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {delegate} from "tippy.js";
import "tippy.js/dist/tippy.css";
import {Circle, Fill, Stroke, Style, Text} from "ol/style";
import MultiPoint from "ol/geom/MultiPoint";
import Toast from "../../components/Map/common/Toast";
import {Rightbar, RightTab} from "./Rightbar";
import {Feature} from "ol";
import LegendControl from "./tools/LegendControl";
import CanvasScaleLine from 'ol-ext/control/CanvasScaleLine';
import CanvasTitle from 'ol-ext/control/CanvasTitle';
import CanvasAttribution from 'ol-ext/control/CanvasAttribution';
import PrintDialog from "ol-ext/control/PrintDialog";
import PDF from 'jspdf-react'
import MousePosition from 'ol/control/MousePosition'
import {createStringXY} from 'ol/coordinate';

const MapComponent = ({height, layerTask}) => {
    const [center, setCenter] = useState([36.817223, -1.286389]);
    const [zoom, setZoom] = useState(6.0);
    const [map, setMap] = useState(null);
    const [defaultExtent, setDefaultExtent] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState();
    const [selectedWsp, setSelectedWsp] = useState();
    const [kpiData, setKpiData] = useState({});
    const [wspData, setWspData] = useState([]);
    const [wsps, setWsps] = useState(null);
    const [selectedLayer, setSelectedLayer] = useState(null);
    const [properties, setProperties] = useState([]);
    const [highlightLayer, setHighlightLayer] = useState(null);
    const [searchLayer, setSearchLayer] = useState(null);
    const [searchData, setSearchData] = useState();
    const duration = {duration: 1000, maxZoom: 18};
    const workspace = "GENERAL";
    let wspLayer = 'kpi_wsp_v'
    let defaultLayer = 'kpi_county_v';
    const [collapsed, setCollapsed] = useState(true);
    const [collapsedRight, setCollapsedRight] = useState(false);
    const [selected, setSelected] = useState("home");
    const [style, setStyle] = useState();
    const [kpiStyles, setKpiStyles] = useState([]);
    const [wspStyles, setWSPStyles] = useState([]);
    const [visibleStyles, setVisibleStyles] = useState([]);
    const [settings, setSettings] = useState({});
    const [toaster, setToaster] = useState(null);
    const [showAbout, setShowAbout] = useState(false);

    let PageSize = 5;
    let countyId = 0;

    const [rows, setRows] = useState([]);

    const [columns, setColumns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);
    const [showAttributeTable, setShowAttributeTable] = useState(false);
    const [tool, setTool] = useState({title: "Toolbox", component: null});
    const [countyKpiLayer, setCountyKpiLayer] = useState(null);
    const [wspKpiLayer, setWspKpiLayer] = useState(null);
    const [countryLayer, setCountryLayer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [wspVisibility, setWSPVisibility] = useState(false);
    const [onClick, setOnClick] = useState(false);
    const [onHome, setHome] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [labels, setLabels] = useState([]);
    const [showLabel, setShowLabel] = useState(false);


    const [wspAreasSource, setWspAreasSource] =
        useState(null);

    const getText = (feature) => {
        let text = "Label"; //feature.get('name');
        return text;
    };


    const toggleWSP = ({toggle}) => {
        let active;
        if (toggle) {
            active = wspStyles.includes(style.id);
            if (selectedCounty) {
                setKpiData(selectedCounty.getProperties())
                highlightLayer.getSource().clear()
                highlightLayer.getSource().addFeatures([selectedCounty]);
                let extent = highlightLayer.getSource().getExtent();
                map.getView().fit(extent, duration);
                setWspData(wsps.filter(wsp => wsp.properties.county_id === parseInt(selectedCounty.getProperties().county_id)))
            }else{
                highlightLayer?.getSource().clear();
                client.fetchStatistics((data) => {
                    setKpiData(data)
                });
                setWspData([])
                if (map) map.getView().fit(defaultExtent, duration);
            }
        } else {
            active = kpiStyles.includes(style.id);
            if (selectedCounty) {
                setKpiData(selectedCounty.getProperties())
                highlightLayer.getSource().clear()
                highlightLayer.getSource().addFeatures([selectedCounty]);
                let extent = highlightLayer.getSource().getExtent();
                map.getView().fit(extent, duration);
                setWspData([])
            }else{
                highlightLayer?.getSource().clear();
                client.fetchStatistics((data) => {
                    setKpiData(data)
                });
                if (map) map.getView().fit(defaultExtent, duration);
            }

        }
        setStyle({id: style.id, active: active});
        setWSPVisibility(toggle)
    }
    const selectWSP = (wsp) => {
        const params = {
            service: "WFS",
            version: "1.0.0",
            request: "GetFeature",
            typeName: `${workspace}:${wspLayer}`,
            outputFormat: "application/json",
            CQL_FILTER: `wsp_id = ${wsp.id}`
        };
        highlightLayer.getSource().clear()
        fetch(`${api.GEOSERVER_URL + "/ows"}?${client.formData(params)}`)
            .then((res) => res.json())
            .then((response) => {
                const features = new GeoJSON().readFeatures(response);
                let vectorSource = new source.Vector({features: features, wrapX: false});
                let extent = vectorSource.getExtent()
                setSelectedWsp(features)
                setKpiData(features[0].getProperties())
                setWspData(response.features)
                highlightLayer.getSource().addFeatures(features);
                map.getView().fit(extent, duration);
            });
    }
    const createTextStyle = (feature) => {
        return new Text({
            textAlign: "centre",
            textBaseline: "middle",
            font: "Arial",
            text: getText(feature),
            fill: new Fill({color: "#aa3300"}),
            stroke: new Stroke({color: "#ffffff", width: 3}),
            offsetX: 0,
            offsetY: 0,
        });
    };

    // Polygons
    const polygonStyleFunction = (feature) => {
        return new Style({
            stroke: new Stroke({
                color: "blue",
                width: 1,
            }),
            fill: new Fill({
                color: "rgba(0, 0, 255, 0.1)",
            }),
            text: createTextStyle(feature),
        });
    };

    const styles = [
        /* We are using two different styles for the polygons:
         *  - The first style is for the polygons themselves.
         *  - The second style is to draw the vertices of the polygons.
         *    In a custom `geometry` function the vertices of a polygon are
         *    returned as `MultiPoint` geometry, which will be used to render
         *    the style.
         */
        new Style({
            stroke: new Stroke({
                color: "blue",
                width: 3,
            }),
            fill: new Fill({
                color: "rgba(0, 0, 255, 0.1)",
            }),
        }),
        new Style({
            image: new Circle({
                radius: 10,
                fill: new Fill({
                    color: "red",
                }),
            }),
            geometry: function (feature) {
                var coordinates;
                var origGeom = feature.getGeometry();
                //  console.log('origGeom.getType()', origGeom.getType())
                if (
                    origGeom.getType() === "Polygon" ||
                    origGeom.getType() === "MultiPolygon"
                ) {
                    coordinates = feature.getGeometry().getCoordinates()[0];
                } else {
                    coordinates = feature.getGeometry().getCoordinates();
                }
                // console.log('coordinates', coordinates)
                return new MultiPoint(coordinates);
            },
        }),
    ];

    const tooolboxRef = useRef();
    const attributesRef = useRef();
    const toc = useRef();
    const toolbox = useRef();
    const mainRef = useRef();
    const mapRef = useRef();

    const onClose = () => {
        setCollapsed(true);
    };
    const onCloseRightBar = () => {
        setCollapsedRight(true);
    };
    const onCloseToolbox = () => {
        const oltbToolbox = tooolboxRef.current.querySelector("#oltb-toolbox");
        oltbToolbox.classList.remove("oltb-toolbox-section--show");
    };
    const onOpen = (id) => {
        if (selected === id) {
            setCollapsed(!collapsed);
        } else {
            setCollapsed(false);
        }
        clear();
        setCollapsedRight(true)
        setSelected(id);
    };


    const clearMap = () => {
        if (highlightLayer) {
            map.removeLayer(highlightLayer)
            highlightLayer.getSource().clear()
        }

        let layers = []
        layers = map.getLayers().getArray().map((layer, index) => {
            if (index > 0) {
                return layers.concat(visibleLayer(layer));
            }
        });
        layers = layers?.filter(layer => layer).flat(5)
        layers.map(layer => layer.setVisible(false))
        countryLayer?.setVisible(true);
        LayerSwitcher.renderPanel(map, toc.current, {reverse: true});
    }

    const onOpenRightBar = (id) => {
        let title = `${kpis.find(kpi => kpi.style == id)?.title}`
        setCollapsedRight(false);
        setCollapsed(true);
        setTool(null)
        onCloseInfoWindow()

        if (toaster) {
            toaster.remove()
        }
        let active;
        if (wspVisibility) {
            active = wspStyles.includes(id);
        } else {
            active = kpiStyles.includes(id);
        }
        if(!active){
            highlightLayer?.getSource().clear()
        }
        setStyle({id: id, active: active});
    }

    const parser = new WMSCapabilities();
    let excludedLayerGroups = ["history_layers", "editable_layers"];

    const showLabels = (mapObject, item, column) => {
        if (item.legend) {
            let legendURL = item.legend.replace('image%2Fpng', 'application/json');

            fetch(legendURL)
                .then(res => res.json())
                .then((response) => {
                    console.log('Legend', response.Legend)
                    if (response.Legend.length) {
                        let textColor;
                        let iconUrl;
                        let symbolizer = response.Legend[0].rules[0].symbolizers[0];
                        if (symbolizer.Polygon) {
                            textColor = symbolizer.Polygon.stroke
                        } else if (symbolizer.Line) {
                            textColor = symbolizer.Line.stroke
                        } else if (symbolizer.Point) {
                            textColor = symbolizer.Point.graphics[0].fill
                            iconUrl = symbolizer.Point.url
                        }
                        textColor = textColor?.startsWith('#') ? textColor : `#${textColor}`
                        console.log('textColor', textColor)
                        addLabel(mapObject, item, textColor, column);
                    }
                })
        } else {
            addLabel(mapObject, item, '#f4f4f4', column);
        }
    }

    const addLabel = (mapObject, item, textColor, column) => {
        let labelStyle = new Style({
            text: new Text({
                font: '16px Calibri,sans-serif', fill: new Fill({
                    color: textColor,
                })
            })
        });

        const params = {
            service: 'WFS',
            version: '1.0.0',
            request: 'GetFeature',
            typeName: `${workspace}:${item.layer.code}`,
            outputFormat: 'application/json',
        }
        console.log('params', params)

        let layerUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
        console.log('layerUrl', layerUrl)

        console.log('item.layer.code', item.layer.code)
        console.log('labels', labels)
        let label = labels.find(layer => layer.code === item.layer.code)
        console.log('label', label)
        let labelLayer;

        if (label) {
            label.layer.setVisible(true)
        } else {
            labelLayer = new layer.Vector({
                style: function (feature) {
                    var origGeom = feature.getGeometry();
                    if (origGeom.getType() === 'Polygon' || origGeom.getType() === 'MultiPolygon') {
                        labelStyle
                            .getText()
                            .setText([`${column ? feature.get(column) : feature.get('display') ? feature.get('display') : feature.get('label') ? feature.get('label') : ''}`, 'bold 13px Calibri, sans-serif', '', '', '\n', '', '', 'italic 11px Calibri,sans-serif',]);
                        return [labelStyle];
                    }
                }, source: new source.Vector()
            })
            mapObject.addLayer(labelLayer)
            fetch(layerUrl)
                .then(res => res.text())
                .then((response) => {
                    const features = new GeoJSON().readFeatures(response);
                    if (features.length > 0) {
                        labelLayer.getSource().addFeatures(features);
                        setLabels(prevState => {
                            return [...prevState, {code: item.layer.code, layer: labelLayer}];
                        });
                    }
                });
        }

    }

    useEffect(() => {
        if (highlightLayer) {
            map.removeLayer(highlightLayer)
            highlightLayer.getSource().clear()
        }

        if (countyKpiLayer && wspKpiLayer) {
            countyKpiLayer.setVisible(!collapsedRight);
            wspKpiLayer.setVisible(!collapsedRight);

            LayerSwitcher.renderPanel(map, toc.current, {reverse: true});
        }
        closeAttributTable()
        countryLayer?.setVisible(true);
        if (map) map.getView().fit(defaultExtent, duration);
        if (collapsedRight) {
            setSelectedCounty(null)
            setSelectedWsp(null)

        }
    }, [collapsedRight])


    useEffect(() => {
        if (onHome) {
            if (!collapsedRight) {
                if (countyKpiLayer && wspKpiLayer) {
                    if (wspVisibility) {
                        wspKpiLayer.setVisible(true);
                        setWspData(wsps)
                    }else {
                        countyKpiLayer.setVisible(true);
                    }
                    highlightLayer?.getSource().clear();
                    client.fetchStatistics((data) => {
                        setKpiData(data)
                    });
                    if (map) map.getView().fit(defaultExtent, duration);
                    LayerSwitcher.renderPanel(map, toc.current, {reverse: true});
                }
            }
            if (map) map.getView().fit(defaultExtent, duration);
            if (collapsedRight) {
                setSelectedCounty(null)
                setSelectedWsp(null)
            }

        }
        return function cleanUp() {
            setHome(false);
        }
    }, [onHome])


    useEffect(() => {
        console.log('refresh', refresh)
        if (refresh) {
            clearMap()
            setShowInfoWindow(false)
            closeAttributTable()
            if (map) map.getView().fit(defaultExtent, duration);
        }
        return function cleanUp() {
            setRefresh(false);
        }
    }, [refresh])

    useEffect(() => {
        if (countyKpiLayer && wspKpiLayer) {
            let countyabel = labels.find(layer => layer.code === defaultLayer)
            let wspLabel = labels.find(layer => layer.code === wspLayer)

            if (!collapsedRight) {
                countyKpiLayer.getSource().clear()
                wspKpiLayer.getSource().clear()

                if (wspVisibility) {
                    countyKpiLayer.setVisible(false);
                    wspKpiLayer.setVisible(true);
                    wspKpiLayer.getSource().updateParams({STYLES: style?.id})
                    if (countyabel) {
                        countyabel.layer.setVisible(false)
                    }
                    if (wsps) {
                        let wspsFiltered = [];
                        if (selectedWsp) {
                            wspsFiltered = wsps.filter(wsp => wsp.properties.wsp_id === parseInt(selectedWsp.wsp_id))
                        } else if (selectedCounty) {
                            setKpiData(selectedCounty.getProperties())
                            wspsFiltered = wsps.filter(wsp => wsp.properties.county_id === parseInt(selectedCounty.getProperties().county_id))
                        } else {
                            wspsFiltered = wsps
                        }
                        setWspData(wspsFiltered);

                    }
                    showLabels(map, {layer: {code: wspLayer}}, 'wsp_name')
                } else {
                    if (wspLabel) {
                        wspLabel.layer.setVisible(false)
                    }
                    countyKpiLayer.setVisible(true);
                    wspKpiLayer.setVisible(false);
                    countyKpiLayer.getSource().updateParams({STYLES: style?.id, CQL_FILTER: undefined});
                    showLabels(map, {layer: {code: defaultLayer}}, 'county_name')
                }
            }
        } else {
            //national KPI
            client.fetchStatistics((data) => {
                setKpiData(data)
            });

            //load all WSPS on start
            const params = {
                service: "WFS",
                version: "1.0.0",
                request: "GetFeature",
                typeName: `${workspace}:${wspLayer}`,
                outputFormat: "application/json",
            };
            // console.log('click wsp params',params)
            fetch(`${api.GEOSERVER_URL + "/ows"}?${client.formData(params)}`)
                .then((res) => res.json())
                .then((response) => {
                    setWsps(response.features);
                });
        }
    }, [style, selectedCounty, selectedWsp]);



    useEffect(() => {
        setLoading(map == null)
    }, [map, loading])

    useEffect(() => {
        let remotes = [];
        const capOptions = {
            service: "WMS",
            url: `${api.GEOSERVER_URL}/${workspace}/ows`,
        };
        client.fetchCapabilities(capOptions, function (xmlText, error) {
            let settings = {};
            if (xmlText) {
                var result = parser.read(xmlText);
                result.Capability.Layer.Layer.map((xmlLayerGroup) => {
                    let subLayers = [];

                    if (xmlLayerGroup.Name === wspLayer) {
                        let styles = kpis.filter(kpi => xmlLayerGroup.Style.find(style => style.Name === kpi.style)).map(kpi =>  kpi.style);
                        setWSPStyles(styles)
                        settings.wsp = {
                            extent: transformExtent(xmlLayerGroup.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                            styles: styles
                        }
                    }else if (xmlLayerGroup.Name === defaultLayer) {
                        let styles = kpis.filter(kpi => xmlLayerGroup.Style.find(style => style.Name === kpi.style)).map(kpi => kpi.style);
                        setKpiStyles(styles)
                        settings.county = {
                            extent: transformExtent(xmlLayerGroup.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                            styles: styles
                        }
                    }
                    if (xmlLayerGroup.Layer) {
                        xmlLayerGroup.Layer.map((xmlSubLayer) => {
                            let layers = []
                            if (xmlSubLayer.Layer) {
                                xmlSubLayer.Layer.map((xmlLayer) => {
                                    layers.push({
                                        layer: {code: xmlLayer.Name, name: xmlLayer.Title},
                                        extent: transformExtent(xmlLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                        legend: xmlLayer.Style[0].LegendURL[0].OnlineResource,
                                        styles: xmlLayer.Style
                                    })
                                })
                            }
                            if (layers.length > 0) {
                                subLayers.push({
                                    code: xmlSubLayer.Name,
                                    name: xmlSubLayer.Title,
                                    extent: transformExtent(xmlSubLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                    layers: layers
                                })
                            } else {
                                subLayers.push({
                                    layer: {code: xmlSubLayer.Name, name: xmlSubLayer.Title},
                                    extent: transformExtent(xmlSubLayer.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                    legend: xmlSubLayer.Style[0].LegendURL[0].OnlineResource,
                                    styles: xmlSubLayer.Style
                                })
                            }
                        })

                        if (subLayers.length > 0) {
                            remotes.push({
                                code: xmlLayerGroup.Name,
                                name: xmlLayerGroup.Title,
                                legend: xmlLayerGroup.Style.LegendURL,
                                extent: transformExtent(xmlLayerGroup.EX_GeographicBoundingBox, 'EPSG:4326', 'EPSG:3857'),
                                layers: subLayers.reverse(),
                            })
                        }
                    }
                })
                remotes.reverse()
                setSettings(settings)
                setDefaultExtent(settings.county.extent)
                setupMap(remotes, settings);
            } else {
                setLoading(false)
            }
        });

        delegate(mainRef.current, {
            content(reference) {
                const title = reference.getAttribute("title");
                reference.removeAttribute("title");
                return title;
            },
            target: "[data-tippy-content]",
            placement: "right",
            theme: "oltb oltb-themed",
            //delay: [600, 100]
        });
    }, []);

    const closeAttributTable = () => {
        setShowAttributeTable(false)
        mapRef.current.style.height = '100%'
        mapRef.current.style.transition = 'height 100ms'
    }

    const openAttributTable = (layer) => {
        setSelectedLayer(layer)
        setShowAttributeTable(layer)
        mapRef.current.style.height = '60%'
        mapRef.current.style.transition = 'height 500ms'
    }

    const onAttributesSelected = (selected) => {
        console.log('selected', selected)
        let source = highlightLayer?.getSource()
        source.clear();

        if (selected.length) {
            source.addFeatures(selected);
            map.getView().fit(source.getExtent(), duration)
        } else {
            map.getView().fit(defaultExtent, duration)
        }
    }


    const onCloseInfoWindow = () => {
        setSelectedLayers(false)
    }

    const handleAbout = () => {
        setShowAbout(true)
    }

    const clear = () => {
        let layers = []
        layers = map.getLayers().getArray().map((layer, index) => {
            if (index > 0) {
                return layers.concat(visibleLayer(layer));
            }
        });
        layers = layers?.filter(layer => layer).flat(5)
        layers.map(layer => layer.setVisible(false))
        countryLayer?.setVisible(true);
    }

    const setupMap = (remotes, settings) => {
        let extent = settings.county.extent

        let params = {
            LAYERS: `${workspace}:${defaultLayer}`,
            STYLES: settings.county.styles[0],
            crossOrigin: 'anonymous',
            serverType: 'geoserver',
        };

        let countyKpiLayer = new layer.Tile({
            visible: true,
            extent: extent,
            properties: params,
            layer: {code: defaultLayer},
            source: new source.TileWMS({
                url: `${api.GEOSERVER_URL}/${workspace}/wms`,
                params: params,
            }),
        });

        params = {
            LAYERS: `${workspace}:${wspLayer}`,
            STYLES: settings.wsp.styles[0],
            crossOrigin: 'anonymous',
            serverType: 'geoserver',
        };
        let wspKpiLayer = new layer.Tile({
            visible: false,
            properties: params,
            layer: {code: wspLayer},
            source: new source.TileWMS({
                url: `${api.GEOSERVER_URL}/${workspace}/wms`,
                params: params,
            }),
        });

        params = {
            LAYERS: `${workspace}:country`,
            crossOrigin: 'anonymous',
            serverType: 'geoserver',
        };
        let countryLayer = new layer.Tile({
            visible: true,
            properties: params,
            source: new source.TileWMS({
                url: `${api.GEOSERVER_URL}/${workspace}/wms`,
                params: params,
            }),
        });
        setCountryLayer(countryLayer)


        let groups = [];
        groups.push(
            new layer.Group({
                title: "Base map",
                layers: [
                    new layer.Tile({
                        title: "None",
                        visible: false,
                        type: "base",
                    }),
                    new layer.Tile({
                        title: "Open Street Map",
                        visible: false,
                        source: new source.OSM({crossOrigin: 'anonymous',}),
                        type: "base",
                    }),
                    new layer.Tile({
                        title: "ArcGIS World Topo",
                        visible: true,
                        source: new source.XYZ({
                            crossOrigin: 'anonymous',
                            attributions: ['Powered by Esri'],
                            url: "https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
                        }),
                        type: "base",
                    }),
                    new layer.Tile({
                        visible: false, title: 'ArcGIS Street Map', source: new source.XYZ({
                            attributions: ['Powered by Esri'],
                            attributionsCollapsible: true,
                            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
                        }), type: 'base'
                    }),
                    //
                    new layer.Tile({
                        title: 'Esri Satellite',
                        visible: false,
                        source: new source.XYZ({
                            attributions: ['Powered by Esri'],
                            attributionsCollapsible: true,
                            url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                        }), type: 'base'
                    }),
                ],
            })
        );
        let options = {
            target: "ol-map",
            view: new View({
                center: transform(center, "EPSG:4326", "EPSG:3857"),
                zoom: zoom,
            }),
            controls: defaultControls({zoom: false}).extend([
                new Home({
                    element: 'oltb-data',
                    click: function () {
                        console.log("Home");
                        setHome(true)
                    },
                }),
                new ZoomIn({
                    target: "oltb-data",
                    zoomed: function () {
                        console.log("Zoomed in");
                    },
                }),
                new ZoomOut({
                    target: "oltb-data",
                    zoomed: function () {
                        console.log("Zoomed out");
                    },
                }),
                new FullScreen({
                    target: "oltb-data",
                    enter: function (event) {
                        console.log("Enter fullscreen mode", event);
                    },
                    leave: function (event) {
                        console.log("Leave fullscreen mode", event);
                    },
                }),
                new Info({
                    target: 'oltb-data', click: function (active) {
                        console.log('Info');
                        setShowInfoWindow(active)
                    }
                }),

                new Overview({target: "oltb-data"}),
                new GraticuleTool({target: 'oltb-data'}),
                new MyLocation({
                    target: "oltb-data",
                    location: function (location) {
                        console.log("Location", location);
                    },
                    error: function (error) {
                        console.log("Location error", error);
                    },
                }),
                new Refresh({
                    target: "oltb-data",
                    click: function () {
                        setRefresh(true)
                    }
                }),
                new CanvasScaleLine(),
                new CanvasTitle(),
                new CanvasAttribution(),
                new MousePosition({
                    coordinateFormat: createStringXY(6),
                    projection: 'EPSG:4326',
                })
            ]),
        };
        let mapObject = new Map(options);
        const highlightStyle = new Style({
            stroke: new Stroke({
                color: "rgba(0, 204, 255)",
                width: 2,
            }),
        });

        let highlightOverlay = new layer.Vector({
            style: highlightStyle,
            source: new source.Vector({
                crossOrigin: 'anonymous',
            }),
            map: mapObject,
        });
        remotes.map((group, index) => {
            let layers = [];
            if (!excludedLayerGroups.includes(group.code)) {
                group.layers.map((item, index) => {
                    if (item.layers) {
                        let subLayers = [];
                        item.layers.map((subItem, index) => {
                            let layer = getLayer(mapObject, subItem, extent, highlightOverlay)
                            if (layer) {
                                subLayers.push(layer);
                            }
                        })
                        layers.push(new layer.Group({
                            title: item.name,
                            fold: 'open',
                            layers: subLayers,
                        }))
                    } else {
                        let layer = getLayer(mapObject, item, extent, highlightOverlay)
                        if (layer) {
                            layers.push(layer);
                        }
                    }
                })
                groups.push(new layer.Group({
                    title: group.name,
                    fold: 'open',
                    layers: layers,
                }))
            }
        })
        groups.push(wspKpiLayer)
        groups.push(countyKpiLayer)
        groups.push(countryLayer)

        groups.forEach((layerGroup) => {
            mapObject.addLayer(layerGroup);
        });

        setCountyKpiLayer(countyKpiLayer)
        setWspKpiLayer(wspKpiLayer)

        setStyle({id: settings.county.styles[0], active: true});


        if (extent) {
            mapObject.getView().fit(extent, duration);
        }
        LayerSwitcher.renderPanel(mapObject, toc.current, {reverse: true});

        // Add a layer switcher outside the map
        var search = new ol_control_SearchNominatim({
            position: true,
            className:
                "block border disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm",
            placeholder: "Search location...", // Search, with priority to geo position
        });
        var requestData = search.requestData.bind(search);
        search.requestData = function (s) {
            var data = requestData(s);
            data.countrycodes = "ke";
            return data;
        };

        const mousePositionDiv = document.getElementsByClassName("ol-mouse-position");
        var printDialog = new PrintDialog({className: 'ol-print '});
        printDialog.setSize('A4');
        printDialog.on(['print', 'error', 'show', 'hide'], function (e) {
            console.log('print', e)
            if (e.type == 'show') {
                toolbox.current.style.display = 'none'
                mousePositionDiv[0].style.display = 'none'
            } else if (e.type == 'hide') {
                toolbox.current.style.display = 'inline-flex'
                mousePositionDiv[0].style.display = 'block'
            } else if (e.image) {
                if (e.pdf) {
                    // Export pdf using the print info
                    var pdf = new PDF({
                        orientation: e.print.orientation,
                        unit: e.print.unit,
                        format: e.print.size
                    });
                    pdf.addImage(e.image, 'JPEG', e.print.position[0], e.print.position[0], e.print.imageWidth, e.print.imageHeight);
                    pdf.save(e.print.legend ? 'legend.pdf' : 'map.pdf');
                } else {
                    // Save image as file
                    e.canvas.toBlob(function (blob) {
                        var name = (e.print.legend ? 'legend.' : 'map.') + e.imageType.replace('image/', '');
                        this.print.saveAs(blob, name);
                    }, e.imageType, e.quality);
                }
            } else {
                console.warn('No canvas to export');
            }
        })

        mapObject.addControl(search);
        mapObject.addControl(printDialog);
        search.on("select", function (e) {
            if (e.search.geojson) {
                var format = new GeoJSON();
                var f = format.readFeature(e.search.geojson, {
                    dataProjection: "EPSG:4326",
                    featureProjection: mapObject.getView().getProjection(),
                });
                var view = mapObject.getView();
                var resolution = view.getResolutionForExtent(
                    f.getGeometry().getExtent(),
                    mapObject.getSize()
                );
                var zoom = view.getZoomForResolution(resolution);
                var center = getCenter(f.getGeometry().getExtent());
                // redraw before zoom
                setTimeout(function () {
                    view.animate({
                        center: center,
                        zoom: Math.min(zoom, 13),
                    });
                }, 100);
            } else {
                mapObject.getView().animate({
                    center: e.coordinate,
                    zoom: Math.max(mapObject.getView().getZoom(), 13),
                });
            }
        });


        setHighlightLayer(highlightOverlay)
        mapObject.on("singleclick", (evt) => {
            setOnClick(evt)
        });

        mapObject.on("pointermove", function (e) {
            var pixel = mapObject.getEventPixel(e.originalEvent);
            var hit = mapObject.hasFeatureAtPixel(pixel);
            mapObject.getViewport().style.cursor = hit ? "pointer" : "";
        });
        setMap(mapObject);

        //Initialize static managers with reference to map
        const managers = [InfoWindowManager, LayerManager, TooltipManager];
        managers.forEach((manager) => {
            manager.init(mapObject);
        });
    };

    useEffect(() => {
        if (onClick) {
            updateInfoWindow()
            return function cleanup() {
                setOnClick(false)
            };
        }
    }, [onClick])


    const updateInfoWindow = () => {
        highlightLayer?.getSource().clear();
        setSelectedLayers([])
        let parser = new WMSGetFeatureInfo();
        const view = map.getView();
        let layers = [];
        layers = map
            .getLayers()
            .getArray()
            .map((layer, index) => {
                if (index > 0) {
                    return layers.concat(visibleLayer(layer));
                }
            });
        layers = layers.filter((layer) => layer).flat(5);
        let features = []
        let selected = []
        layers.map((layer) => {
            if (layer.getVisible() && layer instanceof TileLayer) {
                const item = layer.get("layer");
                console.log('item', item)
                console.log('showInfoWindow', showInfoWindow)

                if (item) {
                    if (item.code !== defaultLayer && item.code !== wspLayer) {
                        if (showInfoWindow) {
                            var url = layer.getSource().getFeatureInfoUrl(onClick.coordinate, view.getResolution(), view.getProjection(),
                                {
                                    'INFO_FORMAT': 'application/vnd.ogc.gml',
                                    'QUERY_LAYERS': `${workspace}:${item.code}`
                                });
                            fetch(url)
                                .then(res => res.text())
                                .then(function (response) {
                                    let data = parser.readFeatures(response)
                                    features = [...features, ...data];
                                    console.log('features', features)

                                    if (features.length) {
                                        selected = [...selected, {layer: item, feature: features[0]}]
                                        setSelectedLayers(selected)

                                        highlightLayer?.getSource().addFeatures(features);

                                        let extent = highlightLayer.getSource().getExtent();
                                        map.getView().fit(extent, duration);
                                    }
                                })
                        }
                    } else {
                        let url = layer
                            .getSource()
                            .getFeatureInfoUrl(
                                onClick.coordinate,
                                view.getResolution(),
                                view.getProjection(),
                                {
                                    INFO_FORMAT: "application/vnd.ogc.gml",
                                    QUERY_LAYERS: `${workspace}:${item.code}`,
                                    LAYERS: `${workspace}:${item.code}`,
                                    crossOrigin: 'anonymous',
                                }
                            );

                        fetch(url)
                            .then((res) => res.text())
                            .then(function (response) {
                                let features = parser.readFeatures(response);
                                if (features.length) {
                                    let feature = features[0];
                                    countyId = feature.getProperties().county_id;
                                    if (item.code === defaultLayer) {
                                        console.log("county features", feature.getProperties());
                                        setSelectedCounty(feature);
                                       // setSelectedWsp(null);
                                    } else if (item.code === wspLayer) {
                                        setSelectedWsp(feature.getProperties());
                                        setWspData(wsps.filter(wsp => wsp.properties.wsp_id === parseInt(feature.getProperties().wsp_id)))
                                    }
                                    setKpiData(feature.getProperties())
                                    highlightLayer.getSource().addFeatures(features);
                                    let vectorSource = new source.Vector({
                                        features: features,
                                        crossOrigin: 'anonymous',
                                    });
                                    let extent = vectorSource.getExtent();
                                    map.getView().fit(extent, duration);
                                }

                                // console.log('wsps', wsps)
                                if (wsps && item.code !== wspLayer) {
                                    let wspsFiltered = selectedCounty ? wsps.filter(wsp => wsp.properties.county_id === parseInt(selectedCounty.getProperties().county_id)) : wsps
                                    // console.log('wspsFiltered', wspsFiltered)
                                    setWspData(wspsFiltered);
                                }
                            });
                    }
                }
            }
        })
    }

    const flatten = (item) => {
        if (!Array.isArray(item)) {
            return item;
        } else {
            flatten([].concat.apply([], item));
        }
    };
    const visibleLayer = (layer) => {
        if (layer instanceof TileLayer || layer instanceof VectorLayer) {
            return layer;
        } else if (layer instanceof LayerGroup) {
            let layers = layer
                .getLayers()
                .getArray()
                .map((layer) => {
                    if (layer instanceof TileLayer || layer instanceof VectorLayer) {
                        return layer;
                    } else {
                        return visibleLayer(layer);
                    }
                });
            return layers;
        }
    };

    const getLayer = (mapObject, item, extent, highlightOverlay) => {
        const params = {
            LAYERS: workspace + ":" + item.layer.code,
            crossOrigin: 'anonymous',
            serverType: 'geoserver',
            CQL_FILTER: "stage='published'"
        };
        let layerUrl = `${
            api.GEOSERVER_URL + "/" + workspace + "/wms"
        }?${client.formData(params)}`;

        params.item = item;

        let tileLayer = new layer.Tile({
            title: item.legend ? `
        <div class="-mt-1 flex">
            <div class="block">
                 <span class="p-1"> ${item.layer.name}</span>
                 <img class="p-1" src="${item.legend + '&transparent=true'}" /> 
             </div>
        </div>` : `<div class="-mt-1">${item.layer.name}</div>`, actions: [{
                title: 'Show Attributes', click: (event) => {
                    console.log('show attributes')
                    openAttributTable(item.layer)
                    tileLayer.setVisible(true)
                    LayerSwitcher.renderPanel(mapObject, toc.current, {reverse: true});
                }
            },
                {
                    title: 'Show Labels', click: (event) => {
                        tileLayer.setVisible(true)
                        LayerSwitcher.renderPanel(mapObject, toc.current, {reverse: true});
                        showLabels(mapObject, item)
                    }
                }
            ], visible: false,
            extent: item.extent,
            layer: item.layer,
            source: new source.TileWMS({
                url: layerUrl
            })
        });
        const defaultExtent = extent ? extent : item.extent;

        tileLayer.on("change:visible", function () {
            highlightOverlay?.getSource().clear()

            if (tileLayer.getVisible() && showLabel) {
                showLabels(mapObject, item)
            } else {
                let arr = labels.filter(lbl => lbl.code !== item.layer.code)
                console.log('arr', arr)
                highlightOverlay?.getSource().clear()
                setLabels(prevState => {
                    let label = prevState.find(layer => layer.code == item.layer.code)
                    if (label) {
                        mapObject.removeLayer(label.layer)
                    }
                    let arr = prevState.filter(lbl => lbl.code !== item.layer.code);
                    console.log('arr', arr);
                    return arr;
                });
            }
        });

        return tileLayer;
    };

    const handleSearch = (selected, props, mode, query) => {
        setProperties(props)
        const typeName = `${workspace}:${selected.value}`

        let params = {
            LAYERS: typeName,
            crossOrigin: 'anonymous',
            // CQL_FILTER: "stage='published'"
        }

        let layerUrl = `${api.GEOSERVER_URL}/${workspace}/wms?${client.formData(params)}`
        console.log('Search Layer', layerUrl)

        if (searchLayer) {
            map.removeLayer(searchLayer)
            console.log('searchLayer', searchLayer)
        }

        params = {
            service: "WFS",
            version: "1.0.0",
            request: "GetFeature",
            typeName: typeName,
            outputFormat: "application/json",
        };

        if (query) {
            params.CQL_FILTER = encodeURIComponent(`stage='published' AND (${query})`)
        }
        console.log('search params', params)
        highlightLayer.getSource().clear();
        fetch(`${api.GEOSERVER_URL + "/ows"}?${client.formData(params)}`)
            .then((res) => res.text())
            .then((response) => {
                const features = new GeoJSON().readFeatures(response);
                if (features.length) {
                    if (query) {
                        highlightLayer.getSource().addFeatures(features);
                        let vectorSource = new source.Vector({features: features});
                        let extent = vectorSource.getExtent();
                        map.getView().fit(extent, duration);
                        setSearchData(features)
                    }
                    openAttributTable({code: selected.value, name: selected.label})
                } else {
                    Toast.error({text: `No records found for your selection`, autoremove: 3000})
                }
            });

        let tileLayer = new layer.Tile({
            visible: true,
            properties: params,
            layer: {code: selected.value, name: selected.label},
            source: new source.TileWMS({
                url: layerUrl
            })
        });
        setSearchLayer(tileLayer)
        map.addLayer(tileLayer)
        map.getView().fit(defaultExtent, duration);

    }
    return (
        <React.Fragment>
            {loading && (
                <div role="status">
                    <svg aria-hidden="true"
                         className="!absolute z-[9999999999999] h-20 mx-auto w-screen top-1/2 text-gray-200 animate-spin dark:text-gray-600 fill-wsblue-700"
                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            )}

            <main className="flex-grow">
                <div
                    ref={mainRef}
                    id="main"
                    className="main flex relative h-full">
                    <Sidebar
                        id="sidebar"
                        collapsed={collapsed}
                        selected={selected}
                        onOpen={onOpen.bind(this)}
                        onClose={onClose.bind(this)}
                        closeIcon={<FontAwesomeIcon icon={faXmark}/>}
                    >
                        <Tab
                            id="home"
                            header="Layers"
                            icon={<FontAwesomeIcon icon={faLayerGroup}/>}
                        >
                            <button ref={toc} id="layers" className="layer-switcher">
                            </button>
                        </Tab>

                        <Tab
                            id="search"
                            header="Selection"
                            icon={<FontAwesomeIcon icon={faSearch}/>}
                        >
                            <Search layerTask={layerTask} action={handleSearch}/>
                        </Tab>
                        <Tab
                            id="about"
                            anchor="bottom"
                            header="About"
                            onClick={handleAbout}
                            icon={<FontAwesomeIcon icon={faInfoCircle}/>}
                        >
                        </Tab>
                    </Sidebar>

                    <div className="relative w-full  overflow-hidden">
                        <div id="oltb"></div>
                        <div id="oltb-data" ref={toolbox}></div>
                        <div ref={mapRef} id="ol-map"></div>

                        {selectedLayers.length > 0 && (<InfoWindow data={selectedLayers} onClose={onCloseInfoWindow}/>)}
                        {showAttributeTable && (
                            <AttributeTable layer={showAttributeTable} selectedData={searchData}
                                            close={closeAttributTable} onSelected={onAttributesSelected}/>)}


                    </div>

                    {!loading && (
                        <Rightbar
                            id="sidebar"
                            selectedStyle={style}
                            kpiData={kpiData}
                            wspData={wspData}
                            wspVisibility={wspVisibility}
                            toggleWSP={toggleWSP}
                            selectWSP={selectWSP}
                            map={map}
                            collapsed={collapsedRight}
                            legendVisibility={collapsedRight}
                            onOpen={onOpenRightBar.bind(this)}
                            onClose={onCloseRightBar.bind(this)}
                            closeIcon={<FontAwesomeIcon icon={faXmark}/>}
                        >
                            {
                                kpis.map(kpi => (
                                    <RightTab
                                        key={kpi.style}
                                        id={kpi.style}
                                        header={kpi.title}
                                        icon={<img src={kpi.icon} className="image-icon"/>}
                                    />
                                ))
                            }
                        </Rightbar>
                    )}
                </div>

                {
                    /*
                    <!-- About modal -->
    <div id="aboutModal" tabindex="-1" aria-hidden="true" data-modal-placement="center"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
        <div class="relative w-full h-full max-w-2xl md:h-auto">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow">
                <!-- Modal header -->

                <!-- Modal body -->

            </div>
        </div>
    </div>
    <!-- END About modal -->
                     */
                }

                <Modal
                    show={showAbout}
                    popup={true}
                    onClose={() => setShowAbout(false)}
                >
                    <Modal.Header>
                        <div className="flex items-start justify-between p-4 border-b rounded-t ">
                            <h3 className="text-xl font-semibold text-gray-900">
                                About
                            </h3>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="p-6 space-y-6 text-sm text-gray-500">
                            <div className="border-b border-wsblue-200 pb-6">
                                <img src={majidataLogo} alt="Majidata" className="h-8 mx-auto mt-2 mb-6"/>
                                <p className="mb-4">MajiData was launched as the pro-poor database of the Kenyan
                                    water sector covering
                                    all urban low income
                                    areas in Kenya. A comprehensive data collection exercise started in 2009 and
                                    ended in 2011. More
                                    than 1800 urban low
                                    income areas in 212 cities and towns of Kenya were mapped and the data captured.
                                    In 2016, the
                                    database was updated.
                                    Developed initially by WASREB and WSTF with the support of GIZ through Water
                                    Sector Reform
                                    Programme, the database
                                    has now grown with the vision of being the repository for georeferenced water
                                    services data.</p>
                                <p>This new version of Majidata offers not only the pro-poor information and
                                    sanitation
                                    infrastructure, but also Water
                                    Service Providers' service area maps, networks and small scale service providers
                                    within the
                                    counties.</p>
                            </div>

                            <div>
                                <img src={wtfLogo} alt="Water Sector Trust Fund"
                                     className="h-16 mx-auto mb-6"/>
                                <p className="mb-4">The Water Sector Trust Fund (WaterFund) is a Kenyan State
                                    Corporation under the
                                    Ministry of Water &
                                    Sanitation and Irrigation and established under the Water Act, 2016, with the
                                    mandate to provide
                                    conditional and
                                    unconditional grants to the Counties and to assist in financing the development
                                    of and
                                    management of water and
                                    sanitation services in the marginalised and underserved areas.</p>
                                <p>It previously existed as the Water Services Trust Fund prior to the repeal of the
                                    Water Act,
                                    2002, which had
                                    established it.</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </main>
        </React.Fragment>
    );
};
export default MapComponent;
