import React, {useEffect, useState} from 'react'
import * as client from "../apis/APIClient";
import Select from 'react-select';
import {kpis} from '../config';
import "./form.css"
import chroma from 'chroma-js';
import {YearRangePicker} from 'react-year-range-picker';
import {Toaster, toast} from 'react-hot-toast';
import * as Yup from "yup";
import {useFormik, Formik, Form} from "formik";

const RequestForm = () => {
    const colourStyles = {
        input: (baseStyles) => ({
            ...baseStyles,
            color: 'transparent'
        }),
        control: (styles) => ({
            ...styles, backgroundColor: 'white',
            // border:"1px solid #324EDA"
            boxShadow: "none",
            borderColor: "#cccccc",
            "&:hover": {
                borderColor: "#324EDA"
            },

        }),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            const color = chroma("#324EDA");
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? "#324EDA"
                        : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : "#324EDA",
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? "#324EDA"
                            : color.alpha(0.3).css()
                        : undefined,
                },
            };
        },
        //Multi
        multiValue: (styles, {data}) => {
            const color = chroma("#324EDA");
            return {
                ...styles,
                backgroundColor: color.alpha(0.9).css(),
            };
        },
        multiValueLabel: (styles, {data}) => ({
            ...styles,
            color: "#fff",
        }),
        multiValueRemove: (styles, {data}) => ({
            ...styles,
            color: "#fff",
            ':hover': {
                backgroundColor: "#324EDA",
                color: 'white',
            },
        }),
    };
    const [loading, setLoading] = useState(false)
    const [yearRange, setYearRange] = useState()
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState([]);
    const [selectedWSP, setSelectedWSP] = useState(null);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const kpisOptions = kpis.map(kpi => {
        return {value: kpi.style, label: kpi.title}
    })

    const years = [
        {value: '2022/2023', label: '2022/2023'},
        {value: '2021/2022', label: '2021/2022'},
        {value: '2020/2021', label: '2020/2021'},
        {value: '2019/2020', label: '2019/2020'},
        {value: '2018/2019', label: '2018/2019'},
    ];
    const [selectedYear, setSelectedYear] = useState(years[0]);

    const [excludedLayerGroups, setExcludedLayerGroups] = useState(["history_layers", "editable_layers"]);
    const [workspace, setWorkspace] = useState("GENERAL");
    const [layers, setLayers] = useState([]);
    const [wsps, setWsps] = useState([]);
    const [counties, setCounties] = useState([]);
    const [kpiCategory, setKpiCategory] = useState(true);
    const [gisCategory, setGisCategory] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [aggregation, setAggregation] = useState('WSP');

    useEffect(() => {
        client.getLayers(
            {
                workspace: workspace,
                exclude: excludedLayerGroups,
            },
            (layers) => {
                setLayers(layers)
            }
        );
        client.fetchCounties(
            {
                workspace: workspace,
            },
            (layers) => {
                setCounties(
                    layers?.map((item) => {
                        return (
                            {
                                value: item?.properties?.county_id,
                                label: item?.properties?.countyname
                            }
                        )
                    })
                )
            }
        );
        client.fetchWSPs(
            {
                workspace: workspace,
                cql: `county_id IN (${selectedCounty?.map((item) => {
                    return item?.value
                })})`
            },
            (layers) => {
                setWsps(
                    layers?.map((item) => {
                        return (
                            {
                                value: item?.properties?.gid,
                                label: item?.properties?.label
                            }
                        )
                    })
                )

            }
        );

    }, [selectedCounty])

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <Formik
                initialValues={{
                    kpiCategory: true,
                    gisCategory: false,
                    accepted: '',
                    fullName: '',
                    email: '',
                    institution: '',
                    position: '',
                    year: '',
                    kpis: '',
                    detailedDesc: '',
                    proposedUse: '',
                    proposedOutputs: '',
                    layers: '',
                    comments: '',
                    counties: '',
                    wsps: '',
                    aggregation: 'WSP',
                }}
                validationSchema={Yup.object({
                    kpiCategory: Yup.boolean(),
                    gisCategory: Yup.boolean(),
                    aggregation: Yup.string(),
                    fullName: Yup.string().required("Please Enter Your Names"),
                    email: Yup.string().email().required("Please Enter Your Email"),
                    institution: Yup.string().required("Please Enter Your Institution"),
                    position: Yup.string().required("Please Enter Your Position"),
                    kpis: Yup.array().when("kpiCategory", {
                        is: true,
                        then: () => Yup.array().required("Select the KPIs you want data for")
                    }),
                    layers: Yup.array().when("gisCategory", {
                        is: true,
                        then: () => Yup.array().min(1).required("Select at least one layer")
                    }),
                    counties: Yup.array(),
                    wsps: Yup.array(),
                    detailedDesc: Yup.string().min(20).max(1000).required("Description is required"),
                    proposedUse: Yup.string().min(20).max(1000).required("Proposed use is required"),
                    proposedOutputs: Yup.string().min(20).max(1000).required("Proposed outputs is required"),
                    comments: Yup.string().min(20).max(1000),
                    accepted: Yup.boolean()
                        .required("The terms and conditions must be accepted.")
                        .oneOf([true], "The terms and conditions must be accepted.")
                })}

                onSubmit={(values, {resetForm}) => {
                    console.log('submit', values)
                    let requestForm = {};
            let exclude = ['gisCategory', 'kpiCategory', 'accepted', 'layers', 'counties', 'wsps', 'year', 'kpis', 'aggregation']
            for (let entry of Object.entries(values)) {
                if (!exclude.includes(entry[0])) {
                    requestForm[entry[0]] = entry[1];
                }
            }
            const currentDate = new Date();
            requestForm['requestDate'] = currentDate.toISOString();
            requestForm['verified'] = values.accepted;
            if (values.kpiCategory) {
                requestForm['kpiDataRequest'] = {
                    dataCategory: "KPI",
                    year: values['year'],
                    layerNames: values['kpis'].map( kpi=>kpi.label),
                    aggregateLevel: values['aggregation']
                }
            }
            if (values.gisCategory) {
                requestForm['utilityDataRequest'] = {
                    dataCategory: "GIS",
                    layerNames: values['layers'],
                    counties: values['counties'].map((item) => item.label),
                    wsps: values['wsps'].map((item) => item.label),
                }
            }
            console.log('requestForm', requestForm)
            setLoading(true)
            client.requestData(requestForm, (response, error) => {
                setLoading(false)
                if (response) {
                    toast.success("Request sent successfully.")
                    resetForm({values: ''})
                } else {
                    console.error('Error:', error);
                    toast.error(error)
                }
            });
                }}>
                {formik => (<form className="container mx-auto py-12" onSubmit={formik.handleSubmit}>
                        <div className="md:flex md:justify-between px-4">
                            <div className="md:basis-1/4">
                                <h2 className="text-xl font-bold text-wsblue-400">General information</h2>
                            </div>
                            <div className="md:basis-3/4 md:border-l md:border-dotted md:pl-12">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label htmlFor="fullName" className="form-label">Name <span
                                            className="text-red-500">*</span></label>
                                        <input type="text" name="fullName" id="name" className="form-input"
                                               onChange={formik.handleChange}
                                               value={formik.values.fullName}/>
                                        {formik.touched.fullName && formik.errors.fullName ? (
                                            <p type="invalid"
                                               className="text-red-600 text-[0.9rem]">{formik.errors.fullName}</p>
                                        ) : null}
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="form-label">Email <span
                                            className="text-red-500">*</span></label>
                                        <input name="email" id="email" className="form-input"
                                               onChange={formik.handleChange}
                                               value={formik.values.email}
                                               invalid={
                                                   formik.touched.email && formik.errors.email ? "true" : "false"
                                               }/>
                                        {formik.touched.email && formik.errors.email ? (
                                            <p type="invalid"
                                               className="text-red-600 text-[0.9rem]">{formik.errors.email}</p>
                                        ) : null}
                                    </div>

                                    <div>
                                        <label htmlFor="institution" className="form-label">Institution<span
                                            className="text-red-500">*</span></label>
                                        <input type="text" name="institution" id="institution" className="form-input"
                                               onChange={formik.handleChange}
                                               value={formik.values.institution}
                                               invalid={
                                                   formik.touched.institution && formik.errors.institution ? "true" : "false"
                                               }/>
                                        {formik.touched.institution && formik.errors.institution ? (
                                            <p type="invalid"
                                               className="text-red-600 text-[0.9rem]">{formik.errors.institution}</p>
                                        ) : null}
                                    </div>

                                    <div>
                                        <label htmlFor="position" className="form-label">Position <span
                                            className="text-red-500">*</span></label>
                                        <input type="text" name="position" id="position" className="form-input"
                                               onChange={formik.handleChange}
                                               value={formik.values.position}
                                               invalid={
                                                   formik.touched.position && formik.errors.position ? "true" : "false"
                                               }/>
                                        {formik.touched.position && formik.errors.position ? (
                                            <p type="invalid"
                                               className="text-red-600 text-[0.9rem]">{formik.errors.position}</p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-t border-gray-100 my-12 leading-none"></div>

                        <div className="md:flex justify-between px-4">
                            <div className="md:basis-1/4">
                                <h2 className="text-xl font-bold text-wsblue-400">Data request</h2>
                            </div>

                            <div className="md:basis-3/4 md:border-l md:border-dotted md:pl-12">
                                <div className="w-full flex-grow">
                                    <input id="kpis" name="kpiCategory" type="checkbox" value="KPI" checked={formik.values.kpiCategory}
                                           className="custom-checkbox mt-3"
                                           onChange={formik.handleChange}/>
                                    <h3 className="form-sub-heading p-2 w-full">KPI data (Optional)</h3>
                                </div>
                                {formik.values.kpiCategory && (<div className="grid md:grid-cols-3 gap-6">
                                    <div>
                                        <label htmlFor="year" className="form-label">Year</label>
                                        <div>
                                            <YearRangePicker classNames="w-full"
                                                             minYear={new Date().getFullYear() - 4}
                                                             maxYear={new Date().getFullYear()}
                                                             onSelect={(startYear, endYear) => {
                                                                 setYearRange({startYear, endYear})
                                                                 formik.values.year = selectedYear.value
                                                             }}
                                                             startYear={yearRange?.startYear}
                                                             endYear={yearRange?.endYear}
                                            />
                                        </div>
                                    </div>

                                    <div>

                                        <label htmlFor="kpis" className="form-label">KPI<span
                                            className="text-red-500">*</span></label>
                                        <Select
                                            id="kpis"
                                            name="kpis"
                                            value={formik.values.kpis}
                                            onChange={(kpis) => {
                                                console.log('kpis',kpis)
                                                formik.setFieldValue("kpis", kpis)
                                            }}
                                            options={kpisOptions}
                                            isMulti
                                            classNamePrefix="select"
                                            styles={colourStyles}
                                            className="Select-container"
                                            invalid={
                                                formik.touched.kpis && formik.errors.kpis ? "true" : "false"
                                            }/>
                                        {formik.touched.kpis && formik.errors.kpis ? (
                                            <p type="invalid"
                                               className="text-red-600 text-[0.9rem]">{formik.errors.kpis}</p>
                                        ) : null}

                                    </div>

                                    <div>
                                        <p className="form-label mb-1">Aggregation<span
                                            className="text-red-500">*</span></p>
                                        <div className="flex bg-gray-50 border border-gray-300 p-2.5 rounded-sm">
                                            <div className="basis-1/ items-center mr-6">
                                                <input id="county" type="radio" value="COUNTY" name="aggregation"
                                                       className="w-3 h-3 mr-2 text-blue-600 bg-gray-100 border-gray-300"
                                                       checked={formik.values.aggregation === 'COUNTY'}
                                                       onChange={formik.handleChange}/>
                                                <label htmlFor="county" className="text-xs text-gray-500">County</label>
                                            </div>
                                            <div className="basis-1/2 items-center">
                                                <input id="wsp" type="radio" value="WSP" name="aggregation"
                                                       className="w-3 h-3 mr-2 text-blue-600 bg-gray-100 border-gray-300"
                                                       checked={formik.values.aggregation === 'WSP' }
                                                       onChange={formik.handleChange}/>
                                                <label htmlFor="wsp" className="text-xs text-gray-500">WSP</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}

                                <div className="flex mt-8">
                                    <input id="gis" type="checkbox" name="gisCategory" value="GIS" checked={formik.values.gisCategory}
                                           className="custom-checkbox mt-3"
                                           onChange={formik.handleChange}/>
                                    <h3 className="form-sub-heading  p-2 w-full">Utility GIS Data (Optional)</h3>
                                </div>

                                {formik.values.gisCategory && (<>
                                    <div className="md:grid grid-cols-2 gap-6">
                                        <div>
                                            <label className="form-label">County</label>
                                            <Select
                                                value={formik.values.countie}
                                                onChange={(counties) => {
                                                    setSelectedCounty(counties)
                                                    formik.setFieldValue("counties", counties)
                                                }}
                                                options={counties}
                                                isMulti
                                                classNamePrefix="select"
                                                styles={colourStyles}

                                            />

                                        </div>

                                        <div>
                                            <label className="form-label">WSP</label>
                                            <Select
                                                value={formik.values.wsps}
                                                onChange={(wsps) => {
                                                    formik.setFieldValue("wsps", wsps)
                                                }}
                                                options={wsps}
                                                isMulti
                                                classNamePrefix="select"
                                                styles={colourStyles}
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-6">
                                        <p className="form-label">Layers <span
                                            className="text-red-500">*</span></p>
                                        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-6">
                                            {layers?.map((item) => {
                                                return (
                                                    <div className="checkbox-container" key={item?.code}>
                                                        <input id="layercounty" type="checkbox" value={`${item?.name}`}
                                                               checked={formik.values.layers.includes(item?.name)}
                                                               name="layers" className="custom-checkbox"
                                                               onChange={(event) => {
                                                                   let layers = []
                                                                   if (event.target.checked && !formik.values.layers.includes(item?.name)) {
                                                                       layers = [...formik.values.layers, item?.name]
                                                                   } else {
                                                                       layers = formik.values.layers.filter((layer) => {
                                                                           return layer !== item?.name
                                                                       });
                                                                   }
                                                                   formik.setFieldValue("layers", layers)
                                                               }}
                                                               invalid={
                                                                   formik.touched.layers && formik.errors.layers ? "true" : "false"
                                                               }/>
                                                        <label htmlFor="layercounty"
                                                               className="custom-checkbox-label">{item?.name}</label>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                        {formik.touched.layers && formik.errors.layers ? (
                                            <p type="invalid"
                                               className="text-red-600 text-[0.9rem]">{formik.errors.layers}</p>
                                        ) : null}
                                    </div>
                                </>)}

                                <div className="mt-6">
                                    <label htmlFor="detailed-description" className="form-label">Detailed description of
                                        dataset  (between 20 and 1,000 characters)<span
                                            className="text-red-500">*</span></label>
                                    <textarea name="detailedDesc" id="detailed-description"
                                              className="form-input h-24"
                                              value={formik.values.detailedDesc}
                                              onChange={formik.handleChange}
                                              invalid={
                                                  formik.touched.detailedDesc && formik.errors.detailedDesc ? "true" : "false"
                                              }></textarea>
                                    {formik.touched.detailedDesc && formik.errors.detailedDesc ? (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.detailedDesc}</p>
                                    ) : null}
                                </div>

                                <div className="mt-6">
                                    <label htmlFor="proposed-use" className="form-label">Proposed use of dataset within
                                        the
                                        project (between 20 and 1,000 characters)<span
                                            className="text-red-500">*</span></label>
                                    <textarea name="proposedUse" id="proposed-use" className="form-input h-24"
                                              onChange={formik.handleChange}
                                              value={formik.values.proposedUse}
                                              invalid={
                                                  formik.touched.proposedUse && formik.errors.proposedUse ? "true" : "false"
                                              }></textarea>
                                    {formik.touched.proposedUse && formik.errors.proposedUse ? (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.proposedUse}</p>
                                    ) : null}
                                </div>

                                <div className="mt-6">
                                    <label htmlFor="proposed-outputs" className="form-label">Proposed outputs from the
                                        analysis
                                        of data and expected date of completion  (between 20 and 1,000 characters) <span
                                            className="text-red-500">*</span></label>
                                    <textarea name="proposedOutputs" id="proposed-outputs"
                                              className="form-input h-24"
                                              onChange={formik.handleChange}
                                              value={formik.values.proposedOutputs}
                                              invalid={
                                                  formik.touched.proposedOutputs && formik.errors.proposedOutputs ? "true" : "false"
                                              }></textarea>
                                    {formik.touched.proposedOutputs && formik.errors.proposedOutputs ? (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.proposedOutputs}</p>
                                    ) : null}
                                </div>

                                <div className="mt-6">
                                    <label htmlFor="comments" className="form-label">Comments (between 20 and 1,000 characters)</label>
                                    <textarea name="comments" id="comments" className="form-input h-24"
                                              value={formik.values.comments}
                                              onChange={formik.handleChange}></textarea>
                                </div>

                                <div className="border-t border-gray-100 my-6 leading-none"></div>

                                <div className="mt-6">
                                    <div className="flex">
                                        <input type="checkbox" name="accepted" id="disclaimer" checked={formik.values.accepted}
                                               value={formik.values.accepted}
                                               onChange={formik.handleChange}
                                               invalid={
                                                   formik.touched.accepted && formik.errors.accepted ? "true" : "false"
                                               }/>
                                        <label className="form-label ml-4" htmlFor="disclaimer">By using this form, I
                                            agree
                                            to
                                            follow WASREB's data protocol and ethical agreements relating to use of data
                                            that
                                            have been agreed between the partners.<span
                                                className="text-red-500">*</span></label>

                                    </div>
                                    {formik.touched.accepted && formik.errors.accepted ? (
                                        <p type="invalid"
                                           className="text-red-600 text-[0.9rem]">{formik.errors.accepted}</p>
                                    ) : null}
                                </div>

                                <div className="mt-6">
                                    <button type='submit'
                                            className="py-4 w-full rounded-sm bg-wsblue-600 text-wsgreen-100 text-xs uppercase font-bold hover:bg-wsgreen-500 hover:text-wsblue-700">
                                        Send request
                                        {loading && <svg aria-hidden="true" role="status"
                                                         className="inline w-5 h-5 mr-1.5 text-white animate-spin"
                                                         viewBox="0 0 100 101" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"/>
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"/>
                                        </svg>}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                )
                }

            </Formik>
        </>
    )
}

export default RequestForm