import React, {useEffect, useState} from "react";
import {
    AppBar,

    Box,
    Button,
    Modal,
    styled,
    Toolbar,
} from "@mui/material";
import logo from "../../assets/img/combined-logo-color.png";
import {useNavigate, useLocation} from "react-router-dom";
import {Navbar, Dropdown, Avatar} from 'flowbite-react'

const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
});
const Icons = styled(Box)(({theme}) => ({
    display: "flex",
    gap: "20px",
    alignItems: "center",
}));



const tabs = [
    {title: "Home", link: '/'},
    {title: "Request for data", link: '/request-data'},
    {title: "Contact Us", link: '/contact-us'},
    {title: "Help", link: '/help'}
];

const Header = () => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    useEffect(() => {
        setActiveTab(activeTab)
    }, [activeTab])
    const navigate = useNavigate();
    const location = useLocation();

    const path = location.pathname;

    const style = (link) => {
        const activate = link !== '/' ? path.startsWith(link) : path === '/'
        return {
            fontSize: 12,
            fontWeight: "600",
            background: activate ? "#d3d9e9" : "white",
            textTransform: "uppercase",
            padding: "5px 15px",
            color: "#25408F"
        }
    }
    return (
        <AppBar position="sticky">
            <Navbar rounded={false} >
                <Navbar.Brand href="/" >
                    <img src={logo} className="sm:h-10" alt="Majidata"/>
                </Navbar.Brand>
                <div className="md:order-2">
                    <Navbar.Toggle/>
                    <Navbar.Collapse className="md:space-x-1">
                        {tabs?.map((tab) => {
                            return (
                                <Navbar.Link
                                    key={tab.title}
                                    href={tab.link}
                                    style={style(tab.link)}
                                    className="text-wsblue-100 flex justify-start rounded-md">
                                    {tab.title}
                                </Navbar.Link>)
                        })}
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </AppBar>
    );
};

export default Header;
