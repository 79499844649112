import {PropTypes} from "prop-types";
import React from "react";
import "./rightbar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight, faCaretLeft} from "@fortawesome/free-solid-svg-icons";
import * as client from "../../../apis/APIClient";
import meteringRatio from "../../../assets/legend/metering-ratio.png";
import nonRevenueWater from "../../../assets/legend/non-revenue-water.png";
import hoursOfSupply from "../../../assets/legend/hours-of-supply-lt-100.png";
import revenueCollection from "../../../assets/legend/revenue-collection.png";
import staffProductivity from "../../../assets/legend/staff-productivity-medium.png";
import waterQuality from "../../../assets/legend/water-quality.png";
import waterCoverage from "../../../assets/legend/water-coverage.png";
import OMCost from "../../../assets/legend/om-cost-coverage.png";

import {Box, CircularProgress, Switch, Typography} from "@mui/material";
import {circularProgressClasses} from "@mui/material/CircularProgress";

function addAlpha(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}
function DoubleCircularProgress(props) {
    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-flex",
                textAlign: "center",
                left: "50%",
                transform: "translateX(-50%)",
            }}
        >
            <CircularProgress
                variant="determinate"
                sx={{
                    color: '#f4f4f4',
                }}
                size="8rem"
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                    color: props.selectedcolor,
                    animationDuration: "1000ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                    },
                }}
                size="8rem"
                thickness={4}
                {...props}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    color="text.secondary"
                    sx={{
                        fontWeight: "700",
                    }}
                >
                    {`${props?.value > 0 ? `${Math.round(props.value)}${props.unit ? ` ${props.unit}` : '%'}` : "N/A"}`}
                </Typography>
            </Box>
        </Box>
    );
}

DoubleCircularProgress.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    // value: PropTypes.number.isRequired,
};

class RightTab extends React.Component {
    static propTypes = {
        map: PropTypes.object,
        toggleWSP: PropTypes.func,
        selectWSP: PropTypes.func,
        wspVisibility: PropTypes.bool,
        selectedStyle: PropTypes.string,
        legendVisibility: PropTypes.bool,
        wspData: PropTypes.array,
        kpiData: PropTypes.object,
        id: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        anchor: PropTypes.oneOf(["top", "bottom"]),
        disabled: PropTypes.bool,
        // Provided by the rightbar; don't mark as required (user doesn't need to include them):
        onClose: PropTypes.func,
        onClick: PropTypes.func,
        closeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        position: PropTypes.oneOf(["left", "right"]),
        active: PropTypes.bool,
    };

    render() {
        const active = this.props.active ? " active" : "";
        var closeIcon;
        if (typeof this.props.closeIcon === "string")
            closeIcon = <FontAwesomeIcon icon={this.props.closeIcon}/>;
        else if (typeof this.props.closeIcon === "object")
            closeIcon = this.props.closeIcon;
        else {
            const closecls = this.props.position === "left" ? faCaretRight : faCaretLeft;
            closeIcon = <FontAwesomeIcon icon={closecls}/>;
        }
        return (
            <div id={this.props.id} className={"rightbar-pane" + active}>
                <h1 className="rightbar-header bg-blue-800">
                    {this.props.header}
                    <div className="rightbar-close" onClick={this.props.onClose}>
                        {closeIcon}
                    </div>
                </h1>
                {this.props.children}
            </div>
        );
    }
}

// https://github.com/facebook/react/issues/2979#issuecomment-222379916
const TabType = PropTypes.shape({
    type: PropTypes.oneOf([RightTab]),
});

class Rightbar extends React.Component {
    state = {
        informationTitles: [],
        wspTitles: [],
        wspVisibility: false,
        green: "#22c55e",
        red: "#ef4444",
        yellow: "#EEEE0B",
        title: "NATIONAL",
        wspData: [],
        kpiData: {},
        tabId: false,
        selectedColor: [],
        active: true,
    };

    componentDidMount() {
        this.setState({
            wspVisibility: this.props.wspVisibility,
            active: this.props.selectedStyle?.active,
            tabId: this.props.selectedStyle?.id,
            wspData: this.props.wspData,
            kpiData: this.props.kpiData,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.kpiData !== this.props.kpiData) {
            this.setState({
                kpiData: this.props.kpiData,
            });
            this.updateStats(this.props.selectedStyle?.id, prevState.active)
        }
        if (prevState.wspData !== this.props.wspData) {
            this.setState({
                wspData: this.props.wspData,
            });
            this.updateStats(this.props.selectedStyle?.id, prevState.active)
        }

        if (prevState.tabId !== this.props.selectedStyle?.id) {
            this.setState({
                tabId: this.props.selectedStyle?.id
            });
            this.updateStats(this.props.selectedStyle?.id, this.props.selectedStyle?.active)
        }
        if (prevState.active !== this.props.selectedStyle?.active) {
            this.setState({
                active: this.props.selectedStyle?.active,
            });
            this.updateStats(this.props.selectedStyle?.id, this.props.selectedStyle?.active)
        }
    }

    updateStats(id, active) {
        this.setState({
            tabId: id,
        });
        this.setState({
            active: active,
        });
        this.setState({
            wspTitles: this.props.wspData?.map((wsp) => this.getInfo(id, wsp.properties)),
            informationTitles: this.getInfo(id, this.props.kpiData),
            title: this.props.kpiData.wsp_name || this.props.kpiData.county_name || "National"
        })
    }

    getInfo(tab, properties) {
        console.log(tab, properties)
        if (properties) {
            let info = {
                id: properties.wsp_id,
                name: properties.wsp_name,
            }
            switch (tab) {
                case "water_coverage":
                    info.percentage = properties?.water_coverage
                    info.properties = [
                        {
                            name: "Total Population Served",
                            value: parseFloat(properties?.total_population_served)  || "No data available"
                        },
                        {
                            name: "Total Population In Service Area",
                            value: parseFloat(properties?.total_population_in_service_area) ||  parseFloat(properties?.population_in_service_area)  || "No data available"
                        },
                    ]
                    info.acceptable = "80 - 90"
                    info.notAcceptable = "< 80"
                    info.good = "> 90"
                    info.legend = waterCoverage

                    if (parseFloat(info.percentage) > 90) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) >= 80) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break;
                case "non_revenue_water":
                    info.percentage = properties?.nrw
                    info.properties = [
                        {
                            name: "Total Water Produced",
                            value: parseFloat(properties?.total_water_produced) || "No data available"
                        },
                        {
                            name: "Total Billed Volume",
                            value: parseFloat(properties?.total_billed_volume) || "No data available"
                        },
                    ]
                    info.notAcceptable = "> 25"
                    info.acceptable = "20 - 25"
                    info.good = "< 20"
                    info.legend = nonRevenueWater


                    if (parseFloat(info.percentage) < 20) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) <= 25) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break;
                case "metering_ratio":
                    info.percentage = properties?.metering_ratio
                    info.properties = [
                        {
                            name: "Total Active Water Connections",
                            value: parseFloat(properties?.total_active_water_connections) || "No data available"

                        },
                        {
                            name: "Total Number of Metered Connections",
                            value: parseFloat(properties?.total_number_metered_connections) || "No data available"
                        },
                    ]
                    info.notAcceptable = "< 95"
                    info.acceptable = "95 - 99"
                    info.good = "100"
                    info.legend = meteringRatio

                    if (parseFloat(info.percentage) == 100) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) >= 95) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break;
                case "drinking_water_quality":
                    info.percentage = properties?.dwq
                    info.properties = [
                        {
                            name: "Revised no. of tests planned for residual chlorine",
                            value: parseFloat(properties?.revised_no_of_tests_planned_for_residual_chlorine) || "No data available"

                        },
                        {
                            name: "No of Chlorine Tests Conducted",
                            value: parseFloat(properties?.no_of_chlorine_tests_conducted) || "No data available"
                        },
                        {
                            name: "No of Chlorine Tests within Norm",
                            value: parseFloat(properties?.no_of_chlorine_tests_within_norm) || "No data available"
                        },
                        {
                            name: "No of Bacteriological Planned",
                            value: parseFloat(properties?.no_of_bacteriologicalplanned) || "No data available"
                        },
                        {
                            name: "No of Bacteriological Conducted",
                            value: parseFloat(properties?.no_ofbacteriological_conducted) || "No data available"
                        },
                        {
                            name: "No of Bacteriological within Norm",
                            value: parseFloat(properties?.no_of_bacteriological_within_norm) || "No data available"
                        },
                    ]
                    info.notAcceptable = "< 90"
                    info.acceptable = "90 - 95"
                    info.good = "> 95"
                    info.legend = waterQuality

                    if (parseFloat(info.percentage) > 95) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) >= 90) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break;
                case "revenue_collection":
                    info.percentage = properties?.revenue_collection_efficiency
                    info.properties = [
                        {
                            name: "Total Revenue",
                            value: parseFloat(properties?.total_revenue) || "No data available"
                        },
                        {
                            name: "Total Collection",
                            value: parseFloat(properties?.total_collection_including_collection_from_other_services) || "No data available"
                        },
                    ]
                    info.notAcceptable = "< 85"
                    info.acceptable = "85 - 95"
                    info.good = "> 95"
                    info.legend = revenueCollection
                    if (parseFloat(info.percentage) > 95) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) >= 85) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break;
                case "supply_hours":
                    info.percentage = properties.hours_of_supply_hrsperd
                    info.unit = 'Hrs'
                    info.properties = [
                        {
                            name: "Total active water connections",
                            value: parseFloat(properties?.total_active_water_connections) || "No data available"
                        }
                    ]
                    info.notAcceptable = "< 16"
                    info.acceptable = "16 - 20"
                    info.good = "> 20"
                    info.legend = hoursOfSupply

                    if (parseFloat(info.percentage) > 20) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) >= 16) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break;
                case "cost_coverage":
                    info.percentage = properties.om_cost_coverage
                    info.properties = [
                        {
                            name: "Total Personnel expenditures",
                            value: parseFloat(properties?.total_personnel_expenditures) || "No data available"
                        },
                        {
                            name: "Total O+M Expenditures",
                            value: parseFloat(properties?.total_om_expenditures) || "No data available"
                        }
                    ]
                    info.notAcceptable = "> 40"
                    info.acceptable = "30 - 40"
                    info.good = "< 30"
                    info.legend = OMCost

                    if (parseFloat(info.percentage) < 30) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) <= 40) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break;
                case "staff_productivity":
                    info.percentage = properties.staff_productivity_no_staff_per_k_conns
                    info.properties = [
                        {
                            name: "No Of Total Staff",
                            value: parseFloat(properties?.total_staff || properties?.no_of_total_staff)  || "No data"
                        },
                        {
                            name: "Total No. Active Connections",
                            value: parseFloat(properties?.total_active_water_connections) || "No data available"
                        }
                    ]
                    info.notAcceptable = "> 11"
                    info.acceptable = "7 - 11"
                    info.good = "< 7"
                    info.legend = staffProductivity

                    if (parseFloat(info.percentage) < 7) {
                        info.selectedColor =  this.state.green
                    } else if (parseFloat(info.percentage) <= 11) {
                        info.selectedColor =  this.state.yellow
                    } else {
                        info.selectedColor =  this.state.red
                    }
                    break
            }
            this.setState({
                selectedColor: info.selectedColor
            });
            return info;
        }
    }

    static
    propTypes = {
        id: PropTypes.string.isRequired,
        originalTitle: PropTypes.string,
        dataContainer: PropTypes.string,
        dataPlacement: PropTypes.string,
        title: PropTypes.string,
        collapsed: PropTypes.bool,
        position: PropTypes.oneOf(["left", "right"]),
        closeIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
            .isRequired,
        onClose: PropTypes.func,
        onOpen: PropTypes.func,
        updateWindows: PropTypes.func,
        children: PropTypes.oneOfType([PropTypes.arrayOf(TabType), TabType])
            .isRequired,
    };

    updateWindows(e) {
        this.props.updateWindows && this.props.updateWindows();
    }

    onClose(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClose && this.props.onClose();
        this.updateWindows(e);
    }

    onOpen(e, tabid) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            tabId: tabid,
        });
        this.props.onOpen && this.props.onOpen(tabid);

        this.updateWindows(e);
    }

    renderTab(tab) {
        var icon;
        if (typeof tab.props.icon === "string")
            icon = <i className={tab.props.icon}/>;
        else if (typeof tab.props.icon === "object") icon = tab.props.icon;
        const active = tab.props.id === this.props.selectedStyle?.id ? " active" : "";
        return (<li
            className={`${active} rightbar-tabs-available mx-auto border rounded-full my-3`}
            key={tab.props.id}
            data-tippy-content={tab.props.header}
            onClick={(e) =>
                this.onOpen(e, tab.props.id) ||
                this.updateStats(tab.props.id, this.props.selectedStyle.active)
            }
        >
            <a className="" href={"#" + tab.props.id} role="tab">
                {icon}
            </a>
        </li>);
    }

    renderPanes(children) {
        return React.Children.map(children, (p) =>
            React.cloneElement(p, {
                onClose: this.onClose.bind(this),
                closeIcon: this.props.closeIcon,
                active: p.props.id === this.props.selectedStyle.id,
                position: this.props.position || "right",
            })
        );
    }

    // Override render() so the <Map> element contains a div we can render to
    render() {
        const position = " rightbar-" + (this.props.position || "right");
        const collapsed = this.props.collapsed ? " collapsed" : "";

        const tabs = React.Children.toArray(this.props.children);
        const toptabs = tabs.filter((t) => t.props.anchor !== "bottom");
        return (
            <aside
                id={this.props.id}
                className={"rightbar ol-touch " + position + collapsed}
                ref={(el) => (this.rootElement = el)}
            >
                <div className="rightbar-tabs bg-white">
                    <ul role="tablist">
                        {" "}
                        {/* Top-aligned */}
                        {toptabs.map((t) => this.renderTab(t))}
                        <Switch
                            checked={this.state.wspVisibility}
                            sx={{
                                border: 1,
                                borderBlockColor: "#5b6faa",
                            }}
                            data-tippy-content="Toggle WSP Data"
                            onChange={(event) => {
                                let wspVisibility = !this.state.wspVisibility
                                this.setState(prevState => ({
                                    wspVisibility: wspVisibility
                                }));
                                this.props.toggleWSP({toggle: wspVisibility});
                                this.updateStats(this.props.selectedStyle?.id, this.props.selectedStyle?.active)
                            }}
                        />
                    </ul>
                </div>
                <div className="rightbar-content  border-l border-gray-100 h-full">
                    {!this.props.legendVisibility && this.state.active && (<div className="legend">
                        <img src={this.state.informationTitles?.legend} style={{height: '40px'}}/>
                    </div>)}
                    {this.renderPanes(this.props.children)}
                    <div className="flex flex-col">
                        <div className="!m-0 ">
                            <h1 className="text-center mb-5 text-xl text-blue-900 !capitalize">
                                {this.state.title?.toUpperCase()}
                            </h1>

                            {!this.state.active && (
                                <div className="py-4 px-4 border-gray-200">
                                    <p className="text-center text-4xl"> {this.props.wspVisibility ? 'No data available' : 'Toggle to view WSP level data'}</p>
                                </div>
                            )}

                            {this.state.active && (
                                <>
                                    <DoubleCircularProgress
                                        value={this.state.informationTitles?.percentage ? parseFloat(this.state.informationTitles?.percentage) : -1}
                                        unit={this.state.informationTitles?.unit}
                                        selectedcolor={this.state.selectedColor}
                                    />

                                    <div id="sector-benchmarks" className="text-center my-2 rounded-lg px-2 py-4">
                                        <h2 className="font-bold uppercase text-center text-xs mb-2 mt-10">Sector
                                            Benchmarks
                                        </h2>
                                        <div id="benchmarks">
                                            <div className="benchmark-small bg-red-500 border border-red-300">
                                                <p x-text="kpi.metricNotAcceptable"
                                                   className="benchmark-small-text p-0 m-0">{this.state.informationTitles?.notAcceptable}</p>
                                                <p className="benchmark-small-text p-0 m-0">Not Acceptable</p>
                                            </div>
                                            <div
                                                className="benchmark-small bg-[rgba(255,255,0)] border border-yellow-200">
                                                <p x-text="kpi.metricAcceptable"
                                                   className="benchmark-small-text p-0 m-0 text-black"> {this.state.informationTitles?.acceptable}
                                                </p>
                                                <p className="benchmark-small-text p-0 m-0 text-black">Acceptable</p>
                                            </div>
                                            <div className="benchmark-small bg-green-500 border border-green-200">
                                                <p x-text="kpi.metricGood"
                                                   className="benchmark-small-text p-0 m-0">{this.state.informationTitles?.good}</p>
                                                <p className="benchmark-small-text p-0 m-0">Good</p>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                        </div>
                    </div>
                    {this.state.active && (
                        <>
                            <div
                                className={`${
                                    !this.state.wspVisibility ? "visible" : "hidden"
                                } bg-gray-100  text-gray-600`}
                            >
                                {this.state.informationTitles?.properties?.map((property, index) => {
                                    return (
                                        <div key={index} className="py-4 px-2 border-b border-gray-200">
                                            <p className="basis-1/3 meta-label">{property?.name?.toUpperCase()}</p>
                                            <p className="basis-2/3 text-4xl">{property?.value?.toLocaleString(undefined, { maximumFractionDigits: 2,})
                                            }</p>
                                        </div>
                                    )
                                })
                                }
                            </div>

                            <div
                                className={`${this.state.wspVisibility && this.state?.wspData
                                        ? "visible"
                                        : "hidden"
                                } bg-blue-800`}
                            >
                                <h1 className=" text-center text-white py-4 capitalize text-base ">
                                    Water Service Providers (WSPs)
                                </h1>
                            </div>
                            <div className={`${this.state.wspVisibility && this.state?.wspData
                                    ? "!visible"
                                    : "!hidden"
                            } `}>

                                {/*  WSP KPIs // */}
                                {this.state.wspTitles?.map((wspItem, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${
                                                this.state.wspVisibility ? "visible" : "hidden"
                                            } uppercase text-sm bg-gray-200 mb-2`}
                                        >
                                            <button className="w-full bg-gray-300 px-1 py-2 flex justify-between" onClick={(evt) => this.props.selectWSP(wspItem)}>
                                                <h1 className=" font-semibold text-black py-1 text-left capitalize text-sm" >
                                                    {wspItem.name}
                                                </h1>
                                                <h1 className="font-semibold text-black py-1 text-left capitalize text-sm"  style={{color: `${wspItem.selectedColor}`}}>
                                                    {wspItem?.percentage
                                                        ? `${wspItem?.percentage?.toLocaleString(undefined, {
                                                            maximumFractionDigits: 2,
                                                        })} %` : "N/A"}
                                                </h1>
                                            </button>
                                            {wspItem?.properties?.map((property, index2) => {
                                                return (
                                                    <div className="flex justify-between text-sm px-1 py-1" key={index2}>
                                                        <p className=" w-3/5 !capitalize">
                                                            {property?.name}
                                                        </p>
                                                        <p>
                                                            {property?.value?.toLocaleString(undefined, {
                                                                maximumFractionDigits: 2,
                                                            })}
                                                        </p>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    )
                                })}
                            </div>
                        </>)}

                </div>
            </aside>
        );
    }
}

export {
    Rightbar
    ,
    RightTab
};
