import {
    clock,
    cost,
    drop,
    nonrevenue,
    ratio,
    revenue,
    staff,
    water,
} from './assets/icons'

const api = {
    GEOSERVER_URL: "https://apimajidata.wasreb.go.ke/geoserver",
    API_URL: "https://apimajidata.wasreb.go.ke/api",
}

const workspace = 'GENERAL'

const kpis = [
    {
        style: 'water_coverage',
        title: 'Water Coverage',
        icon: drop
    },
    {
        style: 'non_revenue_water',
        title: 'Non Revenue Water',
        icon: nonrevenue
    },
    {
        style: 'metering_ratio',
        title: 'Metering Ratio',
        icon: ratio
    },
    {
        style: 'drinking_water_quality',
        title: 'Drinking Water Quality',
        icon: water
    },
    {
        style: 'revenue_collection',
        title: 'Revenue Collection Efficiency',
        icon: revenue
    },
    {
        style: 'supply_hours',
        title: 'Supply Hours',
        icon: clock
    },
    {
        style: 'cost_coverage',
        title: 'Cost Coverage',
        icon: cost
    },
    {
        style: 'staff_productivity',
        title: 'Staff Productivity',
        icon: staff
    }]

 export { api, kpis, workspace};

