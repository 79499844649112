//EXTERNAL
export const GET_WFS = "/ows";
export const GET_WMS = "/wms";
export const GET_LAYERS = "/geoserver/layers";
export const LOGIN = "/auth/signin";
export const CREATE_USER = "/auth/create";
export const PROVIDERS = "/service-providers";
export const GET_PROVIDER_ID = "/service-providers/{id}";
export const PUT_UPDATE_USER = "/{id}";
export const GET_USERS = "/users";
export const GET_APPROVALS = "/tasks";
export const BULK_UPDATE_TASKS = "/tasks/bulk-update";
export const SUBMISSIONS = "/submissions";
export const LAYER_TASKS = "/layer-tasks";
export const FORGOT_PASSWORD = "/auth/forgot-password";
export const CREATE_PASSWORD = "/auth/create-password";
export const CHANGE_PASSWORD = "/auth/change-password";
export const DEFAULT_WORKSPACE = "GENERAL:";
export const KPI_COUNTY = "kpi_county_v";
export const LICENSE_FINAL = "licensed_service_areas_final";
export const DEFAULT_SERVER_TYPE = "geoserver";
export const REQUEST_DATA = "/data/requests";

