import React, {useState, useEffect} from 'react'
import {api, workspace} from "../../../config";
import * as url from "../../../helpers/url_helper";
import * as client from '../../../apis/APIClient';
import {useSelector} from 'react-redux';
import Dragabble from 'react-draggable';
import {
    Button,
    Modal,
    Label,
    TextInput,
    FileInput,
    Textarea,
    Radio,
    Select as SelectInput,
    Badge, Checkbox, Table, Tabs
} from 'flowbite-react'
import {Link} from "react-router-dom"
import Select from 'react-select';
import {faTrash, faPlusCircle, faXmark,} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {CircularProgress} from '@mui/material';
import MUIDataTable from "mui-datatables";
import layersIcon from '../../../assets/icons/layers.svg'
import {messages} from '../../../property_mapping';
import {GeoJSON} from "ol/format";
import * as filters from 'ol/format/filter';

let excludedProps = ['geometry', 'gid', 'geom', 'the_geom', 'objectid', 'prev_id', 'prev_f_id', 'version', 'stage', 'approved_by', 'date_approved', 'status', 'created_by', 'date_created', 'updated_by', 'date_updated', 'netwk_f_id', 'netwk_f', 'network_f', 'boundedBy', 'prev_f_gid', 'feature']
const jwt = JSON.parse(sessionStorage.getItem("jwt")) ? JSON.parse(sessionStorage.getItem("jwt")) : {};

const InfoWindow = ({data, onClose}) => {
    const [tabs, setTabs] = useState([])
    console.log('data', data)
    useEffect(() => {
        let tabs = data.map((layer, index) => {
            let tab = {
                title: layer.layer.name
            }
            let mapping = messages.find(message => message[layer.layer.code])
            console.log(layer.layer.code, mapping)
            if (mapping) {
                tab.data = mapping[layer.layer.code]?.map(column => {
                    let name = column.column_name.toLowerCase()
                    return {
                        name: name,
                        label: column.display_name,
                        value: layer.feature.get(name)
                    }
                }).filter(item => item.value && item.value !== 'Null')
            } else {
                tab.data = Object.entries(layer.feature.getProperties()).filter(prop => !excludedProps.includes(prop[0])).map(prop => {
                    return {
                        name: prop[0],
                        label: prop[0],
                        value: prop[1]
                    }
                }).filter(item => item.value && item.value !== 'Null')
            }
            console.log('tab', tab)
            return tab;
        })
        setTabs(tabs)
        console.log('tabs', tabs)
    }, [data])

    return (<Dragabble>
            <div className="oltb-toolbox-container">
                <div id="oltb-toolbox" className="oltb-toolbox-section--show">
                    <div
                        className="oltb-toolbox-section__header py-0 px-1 bg-blue-800 border-b flex justify-between cursor-pointer">
                        <h4 className="sidebar-header" style={{margin: 0}}> Info Window </h4>
                        <div className="sidebar-close" style={{color: 'white', top: '0.5rem'}}
                             onClick={onClose.bind(this)}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                 data-icon="xmark"
                                 className="svg-inline--fa fa-xmark " role="img"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 320 512">
                                <path fill="currentColor"
                                      d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="oltb-toolbox-section__groups"
                         id="oltb-feature-layers-toolbox-collapsed shadow-md sm:rounded-lg"
                         style={{display: 'block'}}>
                        <div className="oltb-toolbox-section__group">
                            <Tabs.Group
                                aria-label="Tabs with underline"
                                style="underline"
                            >
                                {tabs && tabs.map((tab, index) => (
                                    <Tabs.Item key={index} title={tab.title}>
                                        <table className="w-96 text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead
                                                className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="basis-3/5" className="px-6 py-3">Property</th>
                                                <th scope="basis-2/5">Value</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tab.data?.filter(property => property.value && !property.name.endsWith('_id')).map((property, index) => (
                                                <tr key={index}
                                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td scope="row"
                                                        className="px-6 py-2 font-medium text-gray-900 dark:text-white whitespace-nowrap">{property.label}</td>
                                                    <td>{property.value}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </Tabs.Item>
                                ))}
                            </Tabs.Group>
                        </div>
                    </div>
                </div>
            </div>
        </Dragabble>
    )
}

const Search = ({editableLayers, layerTask, action}) => {
    const [layers, setLayers] = useState([]);
    const [selected, setSelected] = useState('');
    const [properties, setProperties] = useState([]);
    const workspace = 'GENERAL'//layerTask?.workspace ? layerTask.workspace : category !== 'WASREB' && category !== 'COUNTY' ? user.providers[0]?.abbrev : 'WASREB'
    const [rows, setRows] = useState([]);

    useEffect(() => {
        client.getLayers({
            workspace: workspace,
        }, (layers) => {
            setLayers([{
                label: 'Select a layer',
                options: layers.map(layer => ({
                        value: layer.code,
                        label: layer.name
                    })
                )
            }])
        })

    }, [])

    useEffect(() => {
        if (selected) {
            setRows([])
            client.GetProperties({
                layer: `${workspace}:${selected.value}`,
                exclude: excludedProps
            }, function (data, error) {
                if (data) {
                    let properties = Object.values(data.properties).filter(prop => !excludedProps.includes(prop.name))
                    setProperties(properties);
                    //  finish(null, properties)
                }
            })
        }
    }, [selected])

    useEffect(() => {
        console.log('rows', rows)
    }, [rows])

    const addRow = (e) => {
        setRows([...rows, {
            joiner: '',
            column: '',
            operator: '',
            value: '',
            choices: undefined
        }])
    }

    const removeRow = (e, row) => {
        setRows(rows.filter(r => r !== row))
    }

    const finish = (mode, props) => {
        if (mode) {
            let query = '';
            query = rows.map(row => {
                let column = properties.find(property => property.name == row.column)
                console.log(row.column, row.value)
                console.log(row.column, column)
                if (row.value) {
                    query = `${row.joiner ? `${row.joiner} ` : ''}${row.column} ${row.operator} `
                    switch (row.operator) {
                        case 'IN':
                            query += `(${row.value.split(',').map(val => column?.type === 'string' ? `'${val.trim()}'` : val.trim())})`
                            break;
                        default:
                            query += `${column?.type === 'string' ? `'${row.value.trim()}'` : row.value.trim()}`
                    }
                    return query;
                }
            }).filter(row => row)
            action(selected, properties, mode, query.join(''))
        } else {
            console.log('toolbox properties', props)
            action(selected, props)
        }
    }

    return (
        <>
            <div className="mt-4 mb-4">
                <div className="block uppercase font-bold">
                    <Label htmlFor="selection" className="text-wsblue-700"
                           value="Select a Layer"/>
                </div>
                <Select
                    name="selection"
                    options={layers}
                    value={selected}
                    onChange={(layer) => {
                        setSelected(layer)
                    }}
                />
            </div>
            <div>
                {properties && rows.map((row, index) => (
                    <Column key={index} row={row} removeRow={removeRow} columns={properties} index={index}/>
                ))}
            </div>
            {properties && (<div className="block mt-4 overflow-hidden">
                <button onClick={(e) => addRow(e)} data-tippy-content="Click to add column filter(s)"
                        className="text-l text-center float-right text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out">
                    <FontAwesomeIcon icon={faPlusCircle}/>
                </button>
            </div>)}

            <div className="flex mt-4">
                <div className="w-full">
                    <button type="button" onClick={(e) => finish('select')}
                            className="w-full text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 mr-2 py-1 p-2">
                        Search
                    </button>
                </div>
            </div>
        </>
    )
}

const Column = ({row, removeRow, columns, index}) => {
    const operators = ['=', '<', '>', '<=', '>=', '<>', 'LIKE', 'ILIKE', 'BETWEEN', 'IS NULL', 'IN', 'NOT'];
    row.operator = row.operator ? row.operator : operators[0]
    row.column = row.column ? row.column : columns[0]?.label
    row.column = row.column ?? ''


    let column = columns.find(col => col.name == row.column)

    const [choices, setChoices] = useState(row.choices ? row.choices : column?.choices)
    const [type, setType] = useState(column?.type)
    const [value, setValue] = useState(row.value)
    const [joiner, setJoiner] = useState(row.joiner)


    const setColumn = (value) => {
        row.column = value
        let column = columns.find(col => col.name == value);
        let choices = column.choices
        if (column.choices?.length == 0 && column.dependency) {
            column.dependency?.dependencyChoices?.map(choice => {
                choices = [...choices, ...choice.choices]
            })
        }
        setChoices(choices)
        row.choices = choices
        updateValue(choices ? choices[0] : '')
        let type = columns.find(col => col.name == value)?.type;
        // setType(type !== 'string' ? type : 'text')
        setType('text')
    }

    const setOperator = (value) => {
        row.operator = value
    }

    const updateValue = (value) => {
        row.value = value?.trim()
        setValue(row.value)
    }

    const updateJoiner = (value) => {
        row.joiner = value
        setJoiner(value)
    }

    return (
        <div>
            {index > 0 && (<div className="flex p-3">
                <Label className="basis-3/12">
                    <Radio name={'joiner[' + index + ']'} value="OR" checked={joiner === 'OR'}
                           onChange={(e) => updateJoiner(e.target.value)}/>
                    <span className="p-1">OR</span>
                </Label>
                <Label className="basis-3/12">
                    <Radio name={'joiner[' + index + ']'} value="AND" checked={joiner === 'AND'}
                           onChange={(e) => updateJoiner(e.target.value)}/>
                    <span className="p-1">AND</span>
                </Label>
            </div>)}
            <div className="flex">
                <div className="basis-5/12">
                    <SelectInput name={'column[' + index + ']'} required={true} defaultValue={row.column}
                                 onChange={(e) => setColumn(e.target.value)}>
                        {columns && columns.map((col) => (
                            <option key={col.name} value={col.name}>{col.label ?? col.name}</option>
                        ))}
                    </SelectInput>
                </div>
                <div className="basis-3/12">
                    <SelectInput name={'operator[' + index + ']'} required={true} defaultValue={row.operator}
                                 onChange={(e) => setOperator(e.target.value)}>
                        {operators.map((item) => (
                            <option key={item}
                                    value={item}>{item}</option>
                        ))}
                    </SelectInput>
                </div>

                <div className="basis-3/12">
                    {choices ?
                        (<SelectInput name={'value[' + index + ']'} defaultValue={value}
                                      onChange={(e) => updateValue(e.target.value)}>
                            {choices.map((choice) => (
                                <option key={choice}
                                        value={choice}>{choice}</option>
                            ))}
                        </SelectInput>)
                        : (<TextInput
                            name={'value[' + index + ']'}
                            type={type}
                            placeholder="Value"
                            onChange={(e) => updateValue(e.target.value)}
                        />)
                    }
                </div>
                <div className="basis-1/12 mt-3">
                    <button onClick={(e) => removeRow(e, row)} data-tippy-content="Delete this row"
                            className="text-md text-center float-right text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out">
                        <FontAwesomeIcon icon={faTrash}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

const AttributeTable = ({layer, close, onSelected, selectedData}) => {
    const [isLoading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [features, setFeatures] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        console.log('selectedData', selectedData)
        if (selectedData) {
            setFeatures(selectedData);
            setRows(selectedData.map(feature => feature.getProperties()))
        }
    }, [selectedData])

    useEffect(() => {
        setRows([])
        setLoading(true)
        client.GetProperties({
            layer: `${workspace}:${layer.code}`,
            exclude: excludedProps
        }, function (data, error) {
            let properties = Object.values(data.properties).filter(prop => !excludedProps.includes(prop.name))
            setColumns(properties.map(prop => {
                return {
                    name: prop.name,
                    label: prop.label,
                    options: {
                        filter: false,
                        sort: false,
                    }
                }
            }));

            if (selectedData) {
                setLoading(false)
                setFeatures(selectedData);
                setRows(selectedData.map(feature => feature.getProperties()))
            } else {
                const params = {
                    service: 'WFS',
                    version: '1.0.0',
                    request: 'GetFeature',
                    typeName: `${workspace}:${layer.code}`,
                    outputFormat: 'application/json',
                }
                let layerUrl = `${api.GEOSERVER_URL + url.GET_WFS}?${client.formData(params)}`
                console.log('AttributeTable', layerUrl)
                fetch(layerUrl)
                    .then(res => res.json())
                    .then((response) => {
                        setLoading(false)
                        const features = new GeoJSON().readFeatures(response);
                        // console.log('features', features)
                        setFeatures(features)
                        if (features.length) {
                            setRows(features.map(feature => feature.getProperties()))
                        }
                    })
            }
        })
    }, [layer])

    const tableOptions = {
        filterType: "dropdown",
        responsive: "standard",
        download: false,
        print: false,
        filter: false,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        // rowsSelected: selectedData?.length ? [...Array(selectedData.length).keys()] : [],
        selectableRows: 'multiple', //or single
        selectableRowsOnClick: true,
        selectableRowsHideCheckboxes: true,
        onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
            if (onSelected) {
                onSelected(features.filter((feature, index) => rowsSelected.includes(index)))
            }
        },
        onRowsDelete: () => {
            if (onSelected) {
                onSelected([])
            }
        }
    };

    return (<div style={{height: "40%", overflow: "auto"}}>
        <div
            className="py-2 px-4 text-white  uppercase font-bold bg-slate-800 border-b border-slate-700 flex justify-between cursor-pointer overflow-hidden">
            {layer && (<div className="flex justify-start">
                {layer.name}
            </div>)}
            <div onClick={() => close(false)}>
                <FontAwesomeIcon icon={faXmark}/>
            </div>
        </div>
        <div>
            <MUIDataTable title={
                <p>
                    {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}}/>}
                </p>}
                          data={rows}
                          columns={columns}
                          options={tableOptions}

            />
        </div>
    </div>)
}
export {InfoWindow, Search, AttributeTable}