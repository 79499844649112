import logo from "./logo.svg";
import "./flowbite.min.css";
import "./App.css";
import Route from "./Routes";
import React from "react";

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
