import React, {useState} from 'react'
import Header from '../Layouts/Header'
import Foot from '../Layouts/Footer'
import VerticalTab from './VerticalTab'
import user_guide from "../../assets/files/user_guide.pdf";

const Help = () => {
    let content = [
        {
            title: "Navigation",
            topics: [
                {
                    title: 'Home Page Navigation',
                    description: "The homepage has a left panel and right panel to display distinct data sets as follows: " +
                        "<ul class='py-3 px-4'>" +
                        "<li><b>GIS Utility Data</b> This is found on the left of the home page</li>" +
                        "<li><b>KPI Data</b> This is found on the right panel of the home page. KPI data is displayed by default when you load the page</li>" +
                        "</ul>",

                },
            ]
        },
        {
            title: "GIS Utility Data",
            topics: [
                {
                    title: 'GIS Utitity Data',
                    description: "Use the left panel in the home page to access the GIS Utility data. They define assets of the various WSPs and SSSPs in the country",

                },
                {
                    title: 'Show Labels',
                    description: "Labels are displayed on the features on the visible layers on the map",


                },
                {
                    title: 'Show Attributes Table',
                    description: "THis shows a grid of the features in the selected layer",

                },
            ]
        },
        {
            title: "KPI Data",
            topics: [
                {
                    title: 'KPI Data',
                    description: "KPI data is based on the metrics that WASREB uses to evaluate the performance of the Water service providers (WSPS) at the lowest level. This information is the then agrreated and averaged to formualate the county KPI data. The aggregated county data forms the national KPI data.<br/>" +
                        "National KPI data is displayed by default when the page loads",

                },
                {
                    title: 'National KPI Data',
                    description: "National KPI data is displayed by default when the page loads",
                    steps: [
                        "Click on the <b>Home</> button in the maps toolbox to to reload",
                        "Use the KPI selection buttons on the right side bar to switch between the different KPIs",
                    ]

                },
                {
                    title: 'County KPI Data',
                    description: "This is aggregation of WSPs performance in the within the county",
                    steps: [
                        "Click on the county on the map. The Map zooms in to the exxtent of the selected county. The KPI panel on the right also updates to display the KPI scores",
                        "Use the KPI selection buttons on the right side bar to switch between the different KPIs",
                    ]
                },
                {
                    title: 'WSP KPI Data',
                    description: "This is are the WSPs performance for the individual KPIs",
                    steps: [
                        "Click on <b>Toggle WSP Data</b> switch. The map layers switch to the WSP KPI layer view. The KPI panel on the right also updates to display the KPI scores. If a county was selected before clicking the toggle buttton, the county feaure will stay highlighted to display the KPIs within the county.",
                        "To view KPIs for a specific county, click on the WSP in the map. You can also click in the name of the WSP the WSP listing provided in the KPI panel",
                        "Use the KPI selection buttons on the right side bar to switch between the different KPIs",
                    ]
                },
            ]
        },
        {
            title: "Feature Selection",
            topics: [
                {
                    title: 'What is feature selection?',
                    description: "This functionality allows you to perform advanced search on a layer of interest.",
                },
                {
                    title: 'How To Select',
                    steps: [
                        "On the map page, click on the selection button on the left side bar. The selection panel appears",
                        "On the dropdown, select the layer of interest",
                        "Below the layers dropdown, there is a plus (+). Use this button to add a selection criteria",
                        "On clicking the plus (+) button, a row with following details appears" +
                        "<ul class='py-3 px-10'> " +
                        "<li><b>Layer Attributes</b> This is a dropdown of all the fields/properties on the layer that you can use to perform your search</li>" +
                        "<li><b>Operators</b> These are the conditions to apply on the selected property</li>" +
                        "<li><b>Search Term</b> This is the value you want to filter with when performing the search</li>" +
                        "</ul>",
                        "If you add more more than one search criteria, a set of addional operators <b>(AND / OR) </b>  will appear. These are used to join between the multiple criteria provided",
                        "Click on  the  <b>Search</b> button to perform your query. On successful querying, the matched features will be displayed and highlighted on the map. The attributes table will also show at the bottom of the page. If no records matching your query were found, an error message will be displayed",

                    ]
                },
            ]
        },
        {
            title: "Request for Data",
            topics: [
                {
                    title: 'Request for Data',
                    description: "Use this page  to request for more data. Your request will be sent to WASREB for action",
                    steps: [
                        "Click on <b>Request for Data</b> on the navigation  bar at the top of the page. The data request form appears",
                        "Fill in yoir personal details as requested",
                        "Fill in the data request section appropriately. The <b>KPI Data</b>  and  <b>GIS Utility Data</b> sections are both optional. However, when the corresponding checkbox is checked, the mandatory fields (marked with red asteriks (*) must be provided  within that section",
                        "Accept the terms and conditions are stated",
                        "Submit your request. You will receive an email with details of the request you have made with a tracking number that you can use to make follow ups with WASREB"
                    ],

                },
            ]
        }
    ];

    return (

        <div className="container mx-auto py-12">
            <h1 className="text-lg">Help</h1>
            <p className="text-right p-3">
                <a className="font-medium text-blue-600 underline" download href={user_guide}>Click to
                    download user guide.pdf</a>
            </p>
            <VerticalTab data={content}/>
        </div>
    )
}

export default Help