import React, {useEffect, useState} from 'react';
import {api} from "../config";
import * as url from "../helpers/url_helper";
import {WFS, GML} from "ol/format";
import XMLParser from "react-xml-parser";
import Toast from '../components/Map/common/Toast';
import {GeoJSON, WMSCapabilities} from "ol/format";
import {messages} from '../property_mapping';

const workspace = "GENERAL"

const formData = (params) => {
    let paramKeys = [];

    if (params) {
        Object.keys(params).map(key => {
            paramKeys.push(key + '=' + params[key]);
            return paramKeys;
        });
    }

    return paramKeys && paramKeys.length ? paramKeys.join('&') : "";
};


const fetchStatistics = (callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        typeName: `${workspace}:kpi_national_v`,
        maxFeatures: 50,
        outputFormat: 'application/json',
    }
    fetch(`${api.GEOSERVER_URL + `/${workspace}` + url.GET_WFS}?${formData(params)}`)
        .then(res => res.json())
        .then((response) => {
                let properties = response.features[0].properties
                if (callback) {
                    callback(properties)
                }

            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
                console.log(error)
            }
        )
}



const fetchCounties = (options, callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        typeName: options.workspace + ':county',
        PROPERTYNAME: 'county_id,countyname',
        authkey: options.authKey,
    }
    let layerUrl = `${api.GEOSERVER_URL}${url.GET_WFS}?${formData(params)}`

    fetch(layerUrl)
        .then(res => res.json())
        .then((response) => {
                console.log('fetchCounties',response)
                if (callback) {
                    callback(response.features)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const fetchWSPs = (options = {}, callback) => {
    const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        typeName: options.workspace + ':wsp_licensed_service_area',
        PROPERTYNAME: 'gid,wsp_name,label',
        authkey: options.authKey,
    }
    if(options.cql){
        params.CQL_FILTER = options.cql
    }
    let layerUrl = `${api.GEOSERVER_URL}${url.GET_WFS}?${formData(params)}`
    console.log('fetchWSPs',layerUrl)
    fetch(layerUrl)
        .then(res => res.json())
        .then((response) => {
                console.log('response',response)
                if (callback) {
                    callback(response.features)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
};


const fetchCapabilities = (options, callback) => {
    const params = {
        service: options.service,
        request: 'GetCapabilities',
       // 'authkey': options.authKey,
    }
    fetch(`${options.url}?${formData(params)}`)
        .then(res => res.text())
        .then((response) => {
                if (callback) {
                    return callback(response)
                }
            },
            (error) => {
                if (callback) {
                    callback(null, error)
                    return null;
                }
            }
        )
};

const getLayers = (options, callback) => {
    const capOptions = {
        service: 'WMS',
        url: `${api.GEOSERVER_URL}/${options.workspace}/ows`,
       // authKey: options.authKey
    }
    const parser = new WMSCapabilities()
    fetchCapabilities(capOptions, (xmlText, error) => {
        let layers = [];
        if (xmlText) {
            var result = parser.read(xmlText);
            result.Capability.Layer.Layer.map((xmlLayerGroup) => {
                if (!options.exclude || (options.exclude && !options.exclude.includes(xmlLayerGroup.Name)))
                    if (xmlLayerGroup.Layer) {
                        xmlLayerGroup.Layer.map((xmlSubLayer) => {
                            if (xmlSubLayer.Layer) {
                                xmlSubLayer.Layer.map((xmlLayer) => {
                                    layers.push({code: xmlLayer.Name, name: xmlLayer.Title})
                                })
                            } else {
                                layers.push({code: xmlSubLayer.Name, name: xmlSubLayer.Title})
                            }
                        })
                    }
            })
            callback(layers)
        }
    })
}

const requestData = (params, callback) => {
    const requestOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
    };
    fetch(`${api.API_URL + url.REQUEST_DATA}`, requestOptions)
        .then(res => res.json())
        .then((response) => {
                console.log('response',response)
                if (callback) {
                    if(response.errors){
                        callback(null, response.errors.join(', '))
                    }else{
                        callback(response)
                    }
                }
            },
            (error) => {
                console.log('error', error)
                if (callback) {
                    callback(null, error)
                }
            }
        )
};

const contactUs = (params, callback) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    };
    fetch(`${api.API_URL}/contact-us`, requestOptions)
        .then(res => res.text())
        .then((response) => {
                console.log('response',response)
                if (callback) {
                    if(response.errors){
                        callback(null, response.errors.join(', '))
                    }else{
                        callback(response)
                    }
                }
            },
            (error) => {
                console.log('error', error)
                if (callback) {
                    if(!error){
                        callback(error)
                    }else {
                        callback(null, error)
                    }
                }
            }
        )
};

const GetProperties = (options, callback, exclude) => {
    const layer = options.layer.split(':')[1];
    let message = messages.find(message => message[layer])
    if(message){
        message =  message[layer]
    }
    console.log('message', message)
    console.log('options', options)
    const params = {
        service: 'WFS',
        version: '1.3.0',
        request: 'describeFeatureType',
        typeName: options.layer,
        outputFormat: 'application/json',
    }
    fetch(`${api.GEOSERVER_URL}/wfs?${formData(params)}`)
        .then(res => res.json())
        .then((response) => {
                let featureProps = exclude ? featureProps.filter(col => !exclude.includes(col.name)) : response.featureTypes[0].properties
                const targetPrefix = response.targetPrefix;
                const targetNamespace = response.targetNamespace;
          //      const type = featureProps.find(prop => prop.name === 'geom' || prop.name === 'the_geom')?.type.split(':')[1];

                const params = {
                    layerName: layer,
                }
                let layerResponse = {
                    targetPrefix: targetPrefix,
                    targetNamespace: targetNamespace,
                 //   type: type,
                }
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                };
                let url = `${api.API_URL}/layer-properties-settings?${formData(params)}`;
                console.log('url', requestOptions)
                fetch(url, requestOptions)
                    .then(res => res.json())
                    .then((response) => {
                            console.log('layer-properties', response)
                            //console.log('messages', messages)

                            let properties = {}

                            featureProps.filter(prop => prop.name !== 'geom').map(prop => {
                                let property = response.find(r => r.propertyName === prop.name)
                                let alias = message?.find(m => m.column_name.toLowerCase() === prop.name.toLowerCase())
                                properties[prop.name] = {
                                    value: '',
                                    name: prop.name,
                                    maxOccurs: prop.maxOccurs,
                                    minOccurs: prop.minOccurs,
                                    nillable: prop.nillable,
                                    type: prop.localType,
                                    choices: property?.choices,
                                    dependency: property?.dependency,
                                    label: alias?.display_name,
                                    description: alias?.description
                                }
                            })

                            layerResponse.properties = properties
                            console.log('layerResponse', layerResponse)
                            if (callback) {
                                callback(layerResponse)
                            }
                        },
                        (error) => {
                            console.log('error', error)
                        }
                    )
            },
            (
                error
            ) => {
                if (callback) {
                    callback(null, error)
                }
            }
        )
}

export {
    formData,
    fetchStatistics,
    fetchCounties,
    requestData,
    contactUs,
    fetchWSPs,
    fetchCapabilities,
    getLayers,
    GetProperties
};