import React from "react";
import "./style.css";
import parse from 'html-react-parser'

function Content(props) {
    let data = props.data;
    console.log('content', data)
    return (
        <div
            key={props.index}
            className="section__Jobs-styledContent"
            style={
                props.activeTabId === props.index
                    ? {display: "block"}
                    : {display: "none"}
            }
        >
            {data.map((topic, index) => (
                <div className="py-2">
                    <h5 className="font-bold">{parse(topic.title)}</h5>
                    {topic.description && (<p>{parse(topic.description)}</p>)}
                    {topic.steps && (
                        <div>
                            <h6 className="font-bold">Steps</h6>
                            {topic.steps && topic.steps.map((step, index) => (
                                <p key={index} className="section__Jobs-detail">{parse(step)}</p>
                            ))}
                        </div>
                    )}
                </div>
            ))
            }

        </div>
    );
}

export default Content;
